<template lang="pug">
    .row 
      div.col-md-6.mt-2
        PaymentConditions
      div.col-md-5.offset-md-1.mt-2(v-show="!orderFormOption.hideTotal")
        AddGlobalDiscount
        TotalDocument(@updateTvaDetails='updateTvaDetails' :canEditDiscount='true' ref="TotalDocument")
        TotalTva.mt-2
</template>
<script>
import PaymentConditions from "@/components/purchase/order-form-provider/builder/PaymentConditions";
import TotalDocument from "@/components/purchase/order-form-provider/builder/TotalOrderFormProvider";
import TotalTva from "@/components/purchase/order-form-provider/builder/TotalTva";
import AddGlobalDiscount from "@/components/purchase/order-form-provider/builder/AddGlobalDiscount";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tvaDetails: [],
    };
  },
  computed: {
    ...mapGetters(["orderFormOption"]),
  },
  methods: {
    updateTvaDetails(val) {
      this.tvaDetails = val;
    },
  },
  components: {
    PaymentConditions,
    TotalDocument,
    TotalTva,
    AddGlobalDiscount,
  },
};
</script>

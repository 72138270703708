<template lang="pug">
#invoiceContainer(v-if="this.$route.fullPath.includes(orderFormProvider.id)")
  .content-loader-center.m-0(v-if='isLoadingOrderFormProvider')
    .text-center.flex-center
      .loading-bg-inner(role='status')
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
      | Chargement de la commande fournisseur...
  div(v-else style="height: calc(100% - 50px) !important")
    OrderFormProviderBuilderHeader(:key="id" @changePreviewKey="changePreviewKey" )
    OrderFormProviderBuilder(v-if="orderFormProviderTabActive == 'order-form-provider-edit'")
    .content-tab-fullpage(v-else-if="orderFormProviderTabActive == 'order-form-provider-pdf'" :key="previewKey")
      OrderFormProviderPdf
    .content-tab-fullpage(v-else-if="orderFormProviderTabActive == 'internal-note'")
      InternalNote
    .content-tab-fullpage(v-else-if="orderFormProviderTabActive == 'attachments'")
      ged-viewer-fullpage(:parentFolder="orderFormProvider.folderId" :fromModule="'orderFormProvider'")
</template>
<script>
import OrderFormProviderBuilderHeader from "@/components/purchase/order-form-provider/OrderFormProviderBuilderHeader.vue";
import OrderFormProviderBuilder from "@/components/purchase/order-form-provider/OrderFormProviderBuilder.vue";
import OrderFormProviderPdf from "@/components/purchase/order-form-provider/OrderFormProviderPdf.vue";
import OrderFormProviderPreview from "@/components/purchase/order-form-provider/OrderFormProviderPreview.vue";
import { mapGetters, mapActions } from "vuex";
import { OrderFormProviderDefaultProperties } from "@/types/api-orisis/models/OrderFormProviderModels";
import InternalNote from "@/components/purchase/order-form-provider/internal-note/InternalNote.vue";
import GedViewerFullpage from "@/components/ged/GedViewerFullpage";

export default {
  name: "edit-order-form-provider",
  ref: "edit-order-form-provider",

  data() {
    return {
      OrderFormProviderDefaultProperties,
      previewKey: 0,
    };
  },
  props: {
    id: {
      default: null,
    },
    mode: {
      default: "order-form-provider-edit",
    },
  },
  watch: {
    async $route(to, from) {
      this.$destroy();
    },
  },
  async created() {
    if (this.orderFormProvider.status > 2) {
      this.changeOrderFormProviderTabActive("order-form-provider-pdf");
    } else {
      this.changeOrderFormProviderTabActive(this.mode);
    }

    // TODO : Mettre le orderFormProvider sur edit à chaque ouverture sauf exeptions
    // TODO : Que se passe t il si je relance l'application sur le devis ?
    // TODO : si je fais cette fonction, elle se déclanche aussi lorsque que je créer un orderFormProvider donc 2 appels
    // il faut qu'elle se déclanche uniquement en cas de reaload
    this.orderFormProviderHeader.referencielTvaId.choice =
      this.institutionSettingsActive.referencielTvas.map((elem) => {
        return {
          id: elem.referencielTva.id,
          label: elem.referencielTva.label,
          value: elem.referencielTva.value,
        };
      });

    if (!this.unitsList || this.unitsList.length == 0) {
      await this.getUnits({}).then((res) => {
        this.orderFormProviderHeader.unitId.choice = res;
      });
    } else {
      this.orderFormProviderHeader.unitId.choice = this.unitsList;
    }

    this.getProductProviders({});
    this.getProducts({});
  },
  computed: {
    ...mapGetters([
      "institutionSettingsActive",
      "isLoadingOrderFormProvider",
      "orderFormProviderTabActive",
      "unitsList",
    ]),
    orderFormProviderHeader: {
      get() {
        return this.$store.getters.getOrderFormProviderHeader;
      },
      set(val) {
        this.$store.commit("setOrderFormProviderHeader", {
          ...orderFormProviderHeader,
          val,
        });
      },
    },
    orderFormProvider: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "getProductProviders",
      "getUnits",
      "changeOrderFormProviderTabActive",
      "getProducts",
    ]),
    changePreviewKey() {
      this.previewKey++;
    },
  },
  components: {
    OrderFormProviderBuilderHeader,
    OrderFormProviderBuilder,
    OrderFormProviderPdf,
    OrderFormProviderPreview,
    InternalNote,
    GedViewerFullpage,
  },
};
</script>

<style scoped>
#invoiceContainer {
  width: 98%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.content-tab-fullpage {
  padding-top: 20px !important;
  background-color: white !important;
  height: 100%;
}
</style>

<template lang="pug">
  div(v-if="!preview")
    span {{text}}
      span(v-if="estimatedDurationNumber> 0" @mouseover="showTools = true" @mouseleave="showTools = false") {{ estimatedDurationNumber }} {{ translateDurationType(estimatedDurationType) }}{{estimatedDurationNumber > 1 && estimatedDurationType != 3 ? 's' : ''}}
        span.pl-1.cursor-pointer.bold(v-if="showTools && !disabled") 
          feather-icon(icon="Edit3Icon" size="18"  @click="edit=true" v-b-tooltip.hover.top="'Modifier la durée'") 
          feather-icon(icon="XIcon" size="18" @click="estimatedDurationNumber=estimatedDurationType=null"  v-b-tooltip.hover.top="'Retirer la durée'") 
      a(v-else  @click="edit = !edit") définir
      .select-date-tool(v-if="edit")
        .row
          .col-md-7(style="padding-top:0!important")
            .row.d-flex.mt-1
              .col-md-6.m-0.p-0
                b-form-input(type="number" @blur="saveAction" v-model="estimatedDurationNumber" min="0" )
              .col-md-6.m-0.p-0
                v-select.w-100(:options="options" @input="saveAction" :reduce="(el) => el.id" v-model="estimatedDurationType")   
            .text-right
              b-button.m-0.mt-1(variant="outline-primary" @click="edit = false") Fermer
  div(v-else-if="estimatedDurationNumber>0")
    div.d-flex
      span {{ text }} {{ estimatedDurationNumber }} {{ translateDurationType(estimatedDurationType) }}{{estimatedDurationNumber > 1 && estimatedDurationType != 3 ? 's' : ''}}
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import { BButton, BFormInput } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'

export default {
  components: {
    flatPickr,
    BButton,
    BFormInput,
    'v-select': vSelect
  },
  props: {
    preview: {
      default: false,
    },
    text: {
      type: String,
      default: 'Durée :'
    },
    disabled: {
      default: false
    },
  },
  data() {
    return {
      edit: false,
      options: [{ label: "Heure", id: 0 }, { label: "Jour", id: 1 }, { label: "Semaine", id: 2 }, { label: "Mois", id: 3 }, { label: "Année", id: 4 }],
      showTools: false,

    }
  },
  computed: {
    estimatedDurationType: {
      get() {
        if (this.document.nature == 0) {
          return this.document.quoteBTP.estimatedDurationType
        } else {
          return this.document.invoiceBTP.estimatedDurationType
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          this.document.quoteBTP.estimatedDurationType = value
        } else {
          this.document.invoiceBTP.estimatedDurationType = value
        }
      }
    },
    estimatedDurationNumber: {
      get() {
        if (this.document.nature == 0) {
          return this.document.quoteBTP.estimatedDurationNumber
        } else {
          return this.document.invoiceBTP.estimatedDurationNumber
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          this.document.quoteBTP.estimatedDurationNumber = value
        } else {
          this.document.invoiceBTP.estimatedDurationNumber = value
        }
      }
    },
    isNullable() {
      if (this.document.nature == 0) {
        return this.document.quoteBTP.estimatedDurationNumber == null
      } else {
        return this.document.invoiceBTP.estimatedDurationNumber == null
      }
    },
    canClose() {
      if (this.document.nature == 0) {
        return (this.document.quoteBTP.estimatedDurationNumber == null && this.document.quoteBTP.estimatedDurationType == null) || (this.document.quoteBTP.estimatedDurationNumber != null && this.document.quoteBTP.estimatedDurationType != null)
      } else {
        return (this.document.invoiceBTP.estimatedDurationNumber == null && this.document.invoiceBTP.estimatedDurationType == null) || (this.document.invoiceBTP.estimatedDurationNumber != null && this.document.invoiceBTP.estimatedDurationType != null)
      }
    },
    document: {
      get() {
        return this.$store.getters.document; 
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      }
    },
  },
  methods: {
    ...mapActions(['updateQuote', 'updateInvoice']),
    translateDurationType(id) {
      if (id != null) {
        return this.options[id].label.toLowerCase();
      } else {
        return null;
      }
    },
    cancelEstimatedDuration() {
      if (this.document.nature == 0) {
        this.document.quoteBTP.estimatedDurationNumber = this.document.quoteBTP.estimatedDurationType = null;
      } else {
        this.document.invoiceBTP.estimatedDurationNumber = this.document.invoiceBTP.estimatedDurationType = null;
      }
      this.saveAction();
    },
    saveAction() {
      if (this.document.nature == 0) {
        this.updateQuote({ quote: this.document, updateState: false })
      } else if (this.document.nature == 1 || this.document.nature == 2 || this.document.nature == 3 || this.document.nature == 4) {
        this.updateInvoice({ invoice: this.document, updateState: false })
      }
    },
  }
}
</script>

<template lang="pug">
    tr(:class="line.isOption ? 'isOption' : ''" v-if="(line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18)" :style="line.type == 2 ? 'page-break-after: always;' : ''")
      OrderFormProviderPreviewDetailCommonLine.m-0(v-for="(key, i) in Object.keys(orderFormProviderHeader)" :key="i"  :dataKey="key" v-if="orderFormProviderHeader[key].display && [7,8,9,10].includes(line.type)" :class="orderFormProviderHeader[key].class" :line="line" :orderFormProviderHeader="orderFormProviderHeader" :editFocus="editFocus" :edit="edit") 
      OrderFormProviderPreviewDetailSectionLine.m-0(v-for="(key, i) in columnTitle" :key="i"  v-if="line.type == 1 || line.type == 2" :line="line" :class="orderFormProviderHeader[key].class" :colspan="setColspanSection(orderFormProviderHeader[key])" :orderFormProviderHeader="orderFormProviderHeader" :dataKey="key" :editFocus="editFocus" :edit="edit")
      //- OrderFormProviderPreviewDetailSubTotalLine.m-0(v-for="(key, i) in ['index', 'description', 'subtotal']" :key="i" :indexArray="index-1" v-if="line.type == 5" :line="line" :class="orderFormProviderHeader[key].class" :colspan="setColspanSubTotalLine(orderFormProviderHeader[key])"  :orderFormProviderHeader="orderFormProviderHeader" :dataKey="key" :editFocus="editFocus" :edit="edit")
      OrderFormProviderPreviewDetailTextLine.m-0(v-for="(key, i) in columnText" :key="i"  :dataKey="key" v-if="line.type == 6" :class="orderFormProviderHeader[key].class" :colspan="setColspanSection(orderFormProviderHeader[key])" :line="line" :orderFormProviderHeader="orderFormProviderHeader" :editFocus="editFocus" :edit="edit")
      OrderFormProviderPreviewDetailBreakLine.m-0(v-for="(key, i) in ['description']" :key="i"  :dataKey="key" v-if="line.type == 3"  :line="line" :orderFormProviderHeader="orderFormProviderHeader" :colspan="setColspanAll(orderFormProviderHeader[key])" :edit="edit" :editFocus="editFocus")
      //- OrderFormProviderPreviewDetailPriceLine.m-0(v-for="(key, i) in ['index', 'description', 'referencielTvaId', 'total']" :key="i"  :dataKey="key" v-if="orderFormProviderHeader[key].display && (line.type == 3)" :class="orderFormProviderHeader[key].class" :line="line" :orderFormProviderHeader="orderFormProviderHeader" :editFocus="editFocus" :edit="edit")
  </template>

<script>
import OrderFormProviderPreviewDetailCommonLine from "@/components/purchase/order-form-provider/preview/table/lines/OrderFormProviderPreviewDetailCommonLine.vue";
import OrderFormProviderPreviewDetailSectionLine from "@/components/purchase/order-form-provider/preview/table/lines/OrderFormProviderPreviewDetailSectionLine.vue";
import OrderFormProviderPreviewDetailTextLine from "@/components/purchase/order-form-provider/preview/table/lines/OrderFormProviderPreviewDetailTextLine.vue";
import OrderFormProviderPreviewDetailBreakPageLine from "@/components/purchase/order-form-provider/preview/table/lines/OrderFormProviderPreviewDetailBreakPageLine.vue";
import OrderFormProviderPreviewDetailBreakLine from "@/components/purchase/order-form-provider/preview/table/lines/OrderFormProviderPreviewDetailBreakLine.vue";
import OrderFormProviderPreviewDetailPriceLine from "@/components/purchase/order-form-provider/preview/table/lines/OrderFormProviderPreviewDetailPriceLine.vue";
// import OrderFormProviderPreviewDetailSubTotalLine from "@/components/purchase/order-form-provider/preview/table/lines/OrderFormProviderPreviewDetailSubTotalLine.vue";
export default {
  data() {
    return {
      edit: false,
      editFocus: false,
    };
  },
  computed: {
    orderFormProviderHeader: {
      get() {
        return this.$store.getters.getOrderFormProviderHeader;
      },
      set(val) {
        this.$store.commit("setOrderFormProviderHeader", {
          ...orderFormProviderHeader,
          val,
        });
      },
    },
    document: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
    columnText() {
      if (this.document.showReferenceColumn) {
        return ["index", "reference", "editor"];
      } else {
        return ["index", "editor"];
      }
    },
    columnTitle() {
      return ["index", "titre"];
    },
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  watch: {
    editFocus(val) {
      if (val == false) {
        this.edit = false;
      }
    },
  },
  methods: {
    setColspanAll(key) {
      if (key.text == "Désignation" || key.text == "Titre") {
        return Object.entries(this.orderFormProviderHeader).filter(
          (el) => el[1].display == true
        ).length;
      } else {
        return 1;
      }
    },
    setColspanSection(key) {
      if (key.text == "Désignation" || key.text == "Titre") {
        return (
          Object.entries(this.orderFormProviderHeader).filter(
            (el) => el[1].display == true
          ).length - 1
        );
      } else {
        return 1;
      }
    },
    setColspanSubTotalLine(key) {
      if (key.text == "Désignation" || key.text == "Titre") {
        return (
          Object.entries(this.orderFormProviderHeader).filter(
            (el) => el[1].display == true
          ).length - 2
        );
      } else {
        return 1;
      }
    },
  },
  components: {
    OrderFormProviderPreviewDetailCommonLine,
    OrderFormProviderPreviewDetailSectionLine,
    OrderFormProviderPreviewDetailTextLine,
    OrderFormProviderPreviewDetailBreakPageLine,
    OrderFormProviderPreviewDetailBreakLine,
    OrderFormProviderPreviewDetailPriceLine,
    // OrderFormProviderPreviewDetailSubTotalLine,
  },
};
</script>

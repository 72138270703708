<template lang="pug">
.h-100
  .justify-content-center.h-100(v-if='isLoading')
    .content-loader-center.m-0.h-100
      .text-center.flex-center
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
  #gedViewer.h-100(v-else style="padding: 20px; border: solid 2px #f1f1f1;")
    .finder(style='max-height: 69px;')
      .content-header.w-100.pb-1
        h3.content-header-title.float-left.m-0.pr-1.mr-1
          | Documents
    .content-header-search.mr-1
      ul.breadcrumb.d-flex.align-items-center
        li.inline-flex.items-center(v-show='breadcrumbFolderList.length > 1' v-for='(breadcrumb, index) in breadcrumbFolderList' :key='index' @click='openFolder(breadcrumb,$event)')
          span.name {{ breadcrumb.name }}
          span.material-icons-outlined
            | {{ (index+1) == breadcrumbFolderList.length ? &apos;arrow_drop_down&apos; : &apos;chevron_right&apos;}}
      b-input-group(style='flex: 1;')
        .position-relative.w-100.d-flex.align-items-center.mb-1
          b-button.p-0.position-absolute.btn-gray.br.border-right-0(variant='transparent' style='left:.5rem')
            span.material-icons-outlined.text-secondary search
          b-form-input#search.w-full.pl-4(v-model='searchDocument' placeholder='Rechercher un fichier' style='border-top-right-radius: 0.357rem; border-bottom-right-radius: 0.357rem;')
    .content-header-actions
      b-button-group.my-2.mb-3
        b-button.p-50.d-flex.align-items-center(v-if="this.selectedItems.length>0 && this.selectedItems.filter(elem=>elem.type=='file').length>0" variant='outline-primary' style='min-height: 38px;' @click='downloadFileMultiple()')
          span.material-icons.text-primary(style='font-size:20px') file_download
          |  T&eacute;l&eacute;charger&nbsp;
          span(v-if='this.selectedItems.length>1') ({{ this.selectedItems.length }})
        b-button.p-50.d-flex.align-items-center(v-if='this.selectedItems.length>0' variant='outline-danger' style='min-height: 38px;' @click='deleteMultiple()')
          span.material-icons.text-danger(style='font-size:20px') delete
          span(v-if='this.selectedItems.length>1') ({{ this.selectedItems.length }})
      b-button.px-1.mr-1(variant='primary' @click='popupEditFolder(0)')
        | + Nouveau
      b-button-group
        b-button.my-0.py-0.px-50(variant='outline-primary' :class="viewList ? 'bg-light-primary' : ''" @click='showList()')
          span.material-icons-outlined.m-0.p-0 {{viewList ? &apos;grid_view&apos; : &apos;format_list_bulleted&apos; }}
        // b-dropdown.bg-outline-primary.btn-group-icon(variant='outline-primary')
          template(#button-content='')
            span.material-icons-outlined.m-0.p-0 settings
          b-dropdown-item.w-full(@click="sort('name')") Trier par Nom
          b-dropdown-item.w-full(@click="sort('type')") Trier par Type

      // .content-header.d-flex.w-100.mb-50
        .content-header-search.flex
          ul.breadcrumb.d-flex.align-items-center
            li.inline-flex.items-center(v-show='breadcrumbFolderList.length > 1' v-for='(breadcrumb, index) in breadcrumbFolderList' :key='index' @click='openFolder(breadcrumb,$event)')
              span.name {{ breadcrumb.name }}
              span.material-icons-outlined
                | {{ (index+1) == breadcrumbFolderList.length ? &apos;arrow_drop_down&apos; : &apos;chevron_right&apos;}}
          b-input-group(style='flex: 1;')
            .position-relative.w-100.d-flex.align-items-center
              b-button.p-0.position-absolute.btn-gray.br.border-right-0(variant='transparent' style='left:.5rem')
                span.material-icons-outlined.text-secondary search
              b-form-input#search.w-full.pl-4(v-model='searchDocument' placeholder='Rechercher un fichier' style='border-top-right-radius: 0.357rem; border-bottom-right-radius: 0.357rem;')
        .content-header-actions
          b-button-group.ml-50
            b-button.p-50.d-flex.align-items-center(v-if="this.selectedItems.length>0 && this.selectedItems.filter(elem=>elem.type=='file').length>0" variant='outline-primary' style='min-height: 38px;' @click='downloadFileMultiple()')
              span.material-icons.text-primary(style='font-size:20px') file_download
              |  T&eacute;l&eacute;charger&nbsp;
              span(v-if='this.selectedItems.length>1') ({{ this.selectedItems.length }})
            b-button.p-50.d-flex.align-items-center(v-if='this.selectedItems.length>0' variant='outline-danger' style='min-height: 38px;' @click='deleteMultiple()')
              span.material-icons.text-danger(style='font-size:20px') delete
              span(v-if='this.selectedItems.length>1') ({{ this.selectedItems.length }})
          b-button.px-1.mr-50(variant='primary' @click='popupEditFolder(0)')
            | +
          b-button-group
            b-button.my-0.py-0.px-50(variant='outline-primary' :class="viewList ? 'bg-light-primary' : ''" @click='showList()')
              span.material-icons-outlined.m-0.p-0 {{viewList ? &apos;grid_view&apos; : &apos;format_list_bulleted&apos; }}
    .justify-content-center.h-100(v-if='isLoadingFolder || isLoadingFoldersList' style='flex: 1;')
      .content-loader-center.m-0.h-100
        .text-center.flex-center
          .loading-bg-inner
            .loader
              .outer
              .middle
              .inner
          .mt-5
            br
            br
            br
            |             Chargement du dossier en cours...
    .w-100(v-else style='flex: 1;')
      .finder.d-flex.flex-column.h-100.w-100
        .finder-list(v-if='viewList && parentFolder')
          table.table.table-striped.w-100(v-if='!isLoadingFolder && items.length>0')
            thead.thead
              tr
                th(scope='col' v-for='field in fields' :key='field.key' :class="[field.tdClass, sortBy === field.key ? sortDirection : '']" :style='field.thStyle' @click='sort(field.key)') {{field.label}}
            draggable(v-model='items' tag='tbody' ghost-class='ghost' :move='onMove' @end='endDrag')
              tr.rowHover(v-for='(item,index) in items' :key='item.id' :itemnumber='item.id' :class="{ 'row-selected': selectedItems.includes(item)}")
                td(v-for='field in fields' :key='field.key' :class='field.tdClass' :style='field.tdStyle' @click='onRowClicked($event, item, index)')
                  .cell-type(v-if="field.key=='type'" :style='bgImageSmall(item.fullPath)')
                    span.folder-application.material-icons(v-if='item.folderApplication') lock
                    span.fake-checkbox(v-if='!item.folderApplication && item.type!=="folder"' @click.stop='selectItem(item)')
                      span.checked
                        span.material-icons.text-white(v-show='selectedItems.includes(item)') done
                  div(v-else-if="field.key=='updatedAt'" style='text-transform : capitalize;')
                    | {{formatDate(item.updatedAt)}}
                  div(v-else-if="field.key=='fileSize'" style='text-transform : capitalize;')
                    span(v-if="item.type=='file'") {{sizeFormat(item.fileSize)}}
                  div(v-else-if="field.key=='name'")
                    b-input-group.m-0.p-0(v-if='itemEdit==item.id' size='small')
                      b-form-input.p-0.px-50(:ref="'input-item-'+item.id" v-model='item.name' style='max-height: 30px; height: 30px' v-on:keyup.enter='renameFile(item)' @focus='$event.target.select()' @click.stop='')
                      b-input-group-append
                        b-button.p-0.px-1(size='sm' text='Button' variant='primary' style='max-height: 30px; height: 30px' @click.stop='renameFile(item)') Renommer
                        b-button.p-0.px-1(size='sm' text='Button' variant='outline-primary' style='max-height: 30px; height: 30px' @click.stop='editItem(0)') X
                    .m-0.p-0(v-else-if='item.folderApplication') {{ item.name }}
                    .m-0.p-0(v-else='' style='width: fit-content; -webkit-user-select: none; /* Safari */  -ms-user-select: none; /* IE 10 and IE 11 */ user-select: none; /* Standard syntax */' @click.stop='editItemDbl(item.id)') {{ item.name }}
                  div(v-else-if="field.key=='action'")
                    .d-flex.justify-content-end.align-items-center.w-100.content-btn(v-if="item.type=='file' && item.fileApplication==false")
                      b-button.p-0.mr-50.rounded.border-dangere.rowHoverElement(variant='transparent' pill @click.stop='deleteFileForm(item)' style="padding: 2px !important;")
                        span.material-icons.text-danger(style='font-size:18px') delete 
                      b-button.d-flex.p-0.rounded.border-primarye(variant='transparent' pill @click.stop='downloadFile(item.fullPath, item.name)' style="padding: 2px !important;")
                        span.material-icons.text-primary(style='font-size:18px') file_download
                  div(v-else='')
                    | {{ item[field.key] }}
        .finder-content(v-if='!viewList && parentFolder && folder.subFolders && folder.subFolders.length>0')
          div(style='padding-top:10px; padding-bottom: 12px;')
            div(@click.right.stop='' @contextmenu.prevent='!itemFolder.folderApplication ? $refs.menuFolder[indexFolder].open($event) : false' v-for='(itemFolder, indexFolder) in folder.subFolders' :key='indexFolder' style='width:100%; ' @dblclick='openFolder(itemFolder,$event)' v-b-tooltip.hover.top='itemFolder.name')
              .content-div-folder
                .ged-grid-item
                  span.material-icons folder
                  p.name
                    | {{ itemFolder.name }}
                  span.folder-application.material-icons(v-if='itemFolder.folderApplication') lock
              vue-context.p-0(ref='menuFolder')
                template
                  li.contextChoice.p-0.text-sm
                    a.text-sm(@click.prevent='popupEditFolder(itemFolder.id)')
                      feather-icon(icon='Edit3Icon' svgclasses='w-3 h-3')
                        | Renommer
        .finder-content(v-if='!viewList && parentFolder && folder.files && folder.files.length>0')
          div(style='width:100%; padding-top:10px')
            div(@click.right.stop='' @contextmenu.prevent='$refs.file[index].open($event)' v-for='(item, index) in folder.files' :key='index' @click='onFile(item, index)')
              .ged-grid-item
                .file-hover(v-b-tooltip.hover.top='item.name')
                  .file-img.d-flex.justify-content-center.m-0.p-2(@click.stop='showFile(item.id)')
                    .m-0.p-0.enableBlur(style='max-height: 190px;' :style='bgImage(item.fullPath)')
                  .file-text.m-0.px-2.text-center(@click.stop='showFile(item.id)')
                    span {{ item.name }} - {{ item.hover }}
                  vue-context.p-0(ref='file')
                    template
                      li.contextChoice.p-0.text-sm
                        a.text-sm(@click.prevent='popupEditFile(item.id)')
                          feather-icon(icon='Edit3Icon' svgclasses='w-3 h-3')
                            | Renommer
                      li.contextChoice.p-0.text-sm
                        a.text-sm(@click.prevent='moveFile(item)')
                          feather-icon(icon='CornerDownRightIcon' svgclasses='w-3 h-3')
                            | D&eacute;placer vers...
                  .file-tools.disableBlur
                    .d-flex.justify-content-center.align-items-center.w-100.content-btn.mb-2(v-if="item.type=='file' && item.fileApplication==false")
                      b-button.d-flex.p-0.mr-1.rounded(variant='primary' pill='' @click='downloadFile(item.fullPath, item.name)')
                        span.material-icons.text-white(style='font-size:20px') file_download
                      b-button.d-flex.p-0.rounded(variant='danger' pill='' @click='deleteFileForm(item)')
                        span.material-icons.text-white(style='font-size:20px') delete
        .w-100(style='flex: 1;')
          .inputDropZoneSidebar
          vue-dropzone#customdropzone.border-primary.w-100(ref='imgDropZone' :options='dropzoneOptions' @vdropzone-file-added='uploadFileAdded')
    LightBox(v-if='folder.files && folder.files.length>0' ref='lightbox' :showCaption='true' :showLightBox='false' :media='folder.files')
    PopupEditFile(ref='popupEditFile' :fromModule='fromModule' :parentId='folder && folder.id ? folder.id : affair.folderId')
    PopupEditFolder(ref='popupEditFolder' :fromModule='fromModule' :fromId='affair.Id' :parentId='folder && folder.id ? folder.id : affair.folderId')
    ejs-sidebar.default-sidebar.items-no-padding(ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
      sidebar-file-content(ref='sidebarFileContent' @closeSidebar="closeSidebar")
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import DragSelect from "drag-select-vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { VueContext } from "vue-context";
import "vue-context/dist/css/vue-context.css";
import LightBox from "vue-it-bigger";
import { mapGetters, mapActions } from "vuex";
import { BTable } from "bootstrap-vue";
import PopupEditFolder from "@/components/ged/PopupEditFolder";
import PopupEditFile from "@/components/ged/PopupEditFile";
import SidebarFileContent from "@/components/ged/SidebarFileContent";
import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  props: {
    fromModule: {
      default: null,
      require: false,
    },
    parentFolder: {
      default: null,
      require: true,
    },
    origine: {
      default: null,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      selectedItems: [],
      sortDirection: "desc",
      sortBy: "type",
      delay: 400,
      clicks: 0,
      clicksEdit: 0,
      timer: null,
      timerEdit: null,
      itemEdit: 0,
      isLoading: true,
      searchDocument: "",
      viewList: false,
      relatedElement: {},
      draggedElement: {},
      fields: [
        {
          key: "id",
          label: "Id",
          sortable: true,
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
          thStyle: { width: "30px" },
        },
        {
          key: "name",
          label: "Nom",
          sortable: true,
        },
        {
          key: "action",
          label: "Poids",
          sortable: true,
          tdClass: "text-right",
          thStyle: { width: "100px" },
        },
        // {
        //   key: 'updatedAt',
        //   label: 'Dernière modification',
        //   sortable: true,
        //   tdClass: 'text-center',
        //   thStyle: { width: "200px" },
        // },
        // {
        //   key: 'fileSize',
        //   label : 'Poids',
        //   sortable: true,
        //   tdClass: 'text-right',
        //   thStyle: { width: "100px" },
        // },
      ],
      draggingRow: null,
      draggingRowIndex: null,
      draggingColumn: null,
      draggingColumnIndex: null,

      breadcrumbFolderList: [],
      dropzoneOptions: {
        hiddenInputContainer: ".inputDropZoneSidebar",
        autoQueue: false,
        url: "ORISIS",
        maxFilesize: 10000000,
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
        dictDefaultMessage: `<i class='fa fa-cloud-upload'></i><p class='text-default'>Faites glisser vos documents ou cliquez ici</p>`,
        manuallyAddFile: true,
        autoProcessQueue: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      ,
      "affair",
      "foldersList",
      "workspaceSelected",
      "isLoadingFolder",
      "isLoadingFoldersList",

      "orderFormProvider",
      "deliveryForm",
      "receiptFormProvider",
      "inventory",
      "document",
      "orderFormCustomer",
    ]),
    items: {
      get() {
        let items = [];
        if (
          this.$store.getters.folder &&
          this.$store.getters.folder?.subFolders?.length > 0
        ) {
          items = this.$store.getters.folder.subFolders.map((elem) => {
            return {
              id: elem.id,
              type: "folder",
              name: elem.name,
              fullPath: "",
              fileSize: 0,
              updatedAt: elem.updatedAt,
              folderApplication: elem.folderApplication,
              fileApplication: elem.folderApplication,
            };
          });
        }
        if (
          this.$store.getters.folder &&
          this.$store.getters.folder?.files?.length > 0
        ) {
          items = items.concat(
            this.$store.getters.folder.files.map((elem) => {
              return {
                id: elem.id,
                type: "file",
                name: elem.name,
                fullPath: elem.fullPath,
                fileSize: elem.fileSize,
                updatedAt: elem.updatedAt,
                folderApplication: elem.folderApplication,
                fileApplication: elem.fileApplication,
              };
            })
          );
        }
        return items
          .filter((elem) =>
            elem.name.toLowerCase().includes(this.searchDocument.toLowerCase())
          )
          .sort((p1, p2) => {
            let modifier = 1;
            if (this.sortDirection === "desc") modifier = -1;
            if (p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier;
            if (p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
            return 0;
          });
      },
      set(value) {
        return value;
      },
    },
    folder: {
      get() {
        return this.$store.getters.folder;
      },
      set(value) {
        return this.$store.commit("SET_FOLDER", value);
      },
    },
  },
  components: {
    BTable,
    vueDropzone: vue2Dropzone,
    PopupEditFolder,
    PopupEditFile,
    LightBox,
    SidebarFileContent,
    VueContext,
    draggable,
    DragSelect,
    "ejs-sidebar": SidebarComponent,
  },
  beforeMount() {
    if (localStorage.getItem("defaultViewListGed")) {
      this.viewList = localStorage.getItem("defaultViewListGed");
    }
  },
  async created() {
    this.isLoading = true;
    this.selectedItems = [];

    if (localStorage.getItem("defaultViewListGed")) {
      this.viewList = localStorage.getItem("defaultViewListGed");
    }
    if (this.parentFolder) {
      await this.getFolderById({
        folderId: this.parentFolder,
      });
      await this.getFoldersTreeById({
        folderId: this.parentFolder,
      });
    }
    this.breadcrumbFolder();
  },
  mounted() {
    this.$nextTick(() => {
      this.isLoading = false;
    });
    this.selectedItems = [];
  },
  methods: {
    ...mapActions([
      "updateFile",
      "getFoldersTreeById",
      "getFolderById",
      "createFile",
      "deleteFiles",
      "deleteFolders",
      "updateFileFolder",
      "updateFolder",
      "uploadFileOrderFormProvider",
      "uploadFileReceiptFormProvider",
      "uploadFileDeliveryForm",
      "uploadFileInventory",
      "uploadFileQuote",
      "uploadFileInvoice",
      "uploadFileCredit",
      "uploadFileOrderFormCustomer",
    ]),
    formatDate,
    deleteMultiple() {
      for (let index = 0; index < this.selectedItems.length; index++) {
        const element = this.selectedItems[index];
        if (element.type == "file") {
          // On gère la suppression du fichier
          this.deleteFiles({
            fileIds: [element.id],
          });
        } else {
          // On gèrera la suppression du dossier
          this.deleteFolders({
            folderIds: [element.id],
          });
        }
      }
      this.$nextTick(() => {
        this.selectedItems = [];
        this.closeSidebar();
      });
    },
    downloadFileMultiple() {
      for (let index = 0; index < this.selectedItems.length; index++) {
        const element = this.selectedItems[index];
        this.downloadFile(element.fullPath, element.name);
      }
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },
    downloadFile(url, label) {
      let mineType = "application/pdf";
      const urlExtension = this.get_url_extension(url);
      switch (urlExtension) {
        case "aac":
          mineType = "audio/aac";
          break;
        case "abw":
          mineType = "application/x-abiword";
          break;
        case "arc":
          mineType = "application/octet-stream";
          break;
        case "avi":
          mineType = "video/x-msvideo";
          break;
        case "azw":
          mineType = "application/vnd.amazon.ebook";
          break;
        case "bin":
          mineType = "application/octet-stream";
          break;
        case "bmp":
          mineType = "image/bmp";
          break;
        case "bz":
          mineType = "application/x-bzip";
          break;
        case "bz2":
          mineType = "application/x-bzip2";
          break;
        case "csh":
          mineType = "application/x-csh";
          break;
        case "css":
          mineType = "text/css";
          break;
        case "csv":
          mineType = "text/csv";
          break;
        case "doc":
          mineType = "application/msword";
          break;
        case "docx":
          mineType =
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          break;
        case "eot":
          mineType = "application/vnd.ms-fontobject";
          break;
        case "epub":
          mineType = "application/epub+zip";
          break;
        case "gif":
          mineType = "image/gif";
          break;
        case "htm":
          mineType = "text/html";
          break;
        case "html":
          mineType = "text/html";
          break;
        case "ico":
          mineType = "image/x-icon";
          break;
        case "ics":
          mineType = "text/calendar";
          break;
        case "har":
          mineType = "application/java-archive";
          break;
        case "jpeg":
          mineType = "image/jpeg";
          break;
        case "jpg":
          mineType = "image/jpeg";
          break;
        case "js":
          mineType = "application/javascript";
          break;
        case "json":
          mineType = "application/json";
          break;
        case "mid":
          mineType = "audio/midi";
          break;
        case "midi":
          mineType = "audio/midi";
          break;
        case "mpeg":
          mineType = "video/mpeg";
          break;
        case "mpkg":
          mineType = "application/vnd.apple.installer+xml";
          break;
        case "opd":
          mineType = "application/vnd.oasis.opendocument.presentation";
          break;
        case "ods":
          mineType = "application/vnd.oasis.opendocument.spreadsheet";
          break;
        case "odt":
          mineType = "application/vnd.oasis.opendocument.text";
          break;
        case "ogg":
          mineType = "audio/ogg";
          break;
        case "ogv":
          mineType = "video/ogg";
          break;
        case "ogx":
          mineType = "application/ogg";
          break;
        case "otf":
          mineType = "font/otf";
          break;
        case "png":
          mineType = "image/png";
          break;
        case "pdf":
          mineType = "application/pdf";
          break;
        case "ppt":
          mineType = "application/vnd.ms-powerpoint";
          break;
        case "pptx":
          mineType =
            "application/vnd.openxmlformats-officedocument.presentationml.presentation";
          break;
        case "rar":
          mineType = "application/x-rar-compressed";
          break;
        case "rtf":
          mineType = "application/rtf";
          break;
        case "sh":
          mineType = "application/x-sh";
          break;
        case "svg":
          mineType = "image/svg+xml";
          break;
        case "swf":
          mineType = "application/x-shockwave-flash";
          break;
        case "tar":
          mineType = "application/x-tar";
          break;
        case "tiff":
          mineType = "image/tiff";
          break;
        case "tif":
          mineType = "image/tiff";
          break;
        case "ts":
          mineType = "application/typescript";
          break;
        case "ttf":
          mineType = "font/ttf";
          break;
        case "vsd":
          mineType = "application/vnd.visio";
          break;
        case "wav":
          mineType = "audio/x-wav";
          break;
        case "weba":
          mineType = "audio/webm";
          break;
        case "webm":
          mineType = "video/webm";
          break;
        case "webp":
          mineType = "	image/webp";
          break;
        case "woff":
          mineType = "font/woff";
          break;
        case "woff2":
          mineType = "font/woff2";
          break;
        case "xhtml":
          mineType = "application/xhtml+xml";
          break;
        case "xls":
          mineType = "application/vnd.ms-excel";
          break;
        case "xlsx":
          mineType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          break;
        case "xml":
          mineType = "application/xml";
          break;
        case "xul":
          mineType = "application/vnd.mozilla.xul+xml";
          break;
        case "zip":
          mineType = "application/zip";
          break;
        case "3gp":
          mineType = "video/3gpp";
          break;
        case "3g2":
          mineType = "video/3gpp";
          break;
        case "7z":
          mineType = "application/x-7z-compressed";
          break;
        default:
          mineType = "text/plain";
      }

      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: mineType });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim();
    },

    onMove({ relatedContext, draggedContext }) {
      this.relatedElement = relatedContext.element;
      this.draggedElement = draggedContext.element;
      if (this.draggedElement.folderApplication == true) {
        return false;
      }
    },
    endDrag(e) {
      this.$nextTick(() => {
        if (
          e.item._underlying_vm_.type == "file" &&
          this.relatedElement.type == "folder"
        ) {
          let fileId = e.item._underlying_vm_.id;
          let fileFolders = [];
          fileFolders.push(this.relatedElement.id);
          this.updateFileFolder({ fileId: fileId, fileFolders: fileFolders });
        } else if (
          e.item._underlying_vm_.type == "folder" &&
          this.relatedElement.type == "folder"
        ) {
          // Déplacer un dossier dans un dossier
          let itemFolder = structuredClone({
            id: e.item._underlying_vm_.id,
            name: e.item._underlying_vm_.name,
            folderApplication: false,
            parentId: this.relatedElement.id,
          });
          this.updateFolder({
            folder: itemFolder,
          });
        }
      });
    },
    sort: function (s) {
      if (s === this.sortBy) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      }
      this.sortBy = s;
    },
    selectItem(item) {
      if (!this.selectedItems.includes(item)) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(
          this.selectedItems.findIndex((elem) => elem == item),
          1
        );
      }
    },

    showFile(id) {
      this.$refs["sidebarFileContent"].getFileDetails(id);
      this.$refs.sidebar.toggle();
    },
    showList() {
      this.viewList = !this.viewList;
      localStorage.setItem("defaultViewListGed", this.viewList);
    },

    editItemDbl(id) {
      this.clicksEdit++;
      if (this.clicksEdit === 1) {
        this.itemEdit = 0;
        this.timerEdit = setTimeout(() => {
          this.clicksEdit = 0;
        }, this.delay);
      } else {
        clearTimeout(this.timerEdit);
        this.clicksEdit = 0;
        this.itemEdit = id;
        this.selectedItems = [];
        if (id > 0) {
          this.$nextTick(() => {
            this.$refs["input-item-" + id][0].focus();
          });
        }
      }
    },
    editItem(id) {
      this.itemEdit = id;
      this.selectedItems = [];
      if (id > 0) {
        this.$refs["input-item-" + id][0].focus();
      }
    },
    renameFile(item) {
      if (item.type == "file") {
        this.updateFile(item);
      } else {
        let itemFolder = structuredClone({
          id: item.id,
          name: item.name,
          folderApplication: false,
          parentId:
            this.folder && this.folder.id
              ? this.folder.id
              : this.affair.folderId,
        });
        this.updateFolder({
          folder: itemFolder,
        });
      }
      this.editItem(0);
    },
    onRowClicked(event, item, index) {
      this.itemEdit = 0;
      if (this.clicksEdit == 0) {
        // if(!event.shiftKey){
        // this.selectedItems=[]
        // this.selectedItems.push(item);
        this.clicks++;
        if (this.clicks === 1) {
          if (!item.folderApplication) {
            // if(this.selectedItems.length>0){
            //   // On gère la sélection multiple
            //   if(!this.selectedItems.includes(item)){
            //     this.selectedItems.push(item)
            //   }else{
            //     this.selectedItems.splice(this.selectedItems.findIndex(elem => elem ==item), 1);
            //   }
            // }else{
            //   this.selectedItems=[]
            //   this.selectedItems.push(item);
            // }
          }

          this.timer = setTimeout(() => {
            this.clicks = 0;
            if (!item.folderApplication) {
              if (this.selectedItems.length < 2) {
                if (item.type == "file") {
                  this.itemEdit = 0;
                  this.showFile(item.id);
                } else if (item.type == "folder") {
                  // this.openFolder(item)
                }
              }
            }
          }, this.delay);
        } else {
          clearTimeout(this.timer);
          this.clicks = 0;
          this.itemEdit = item.id;
          if (item.type == "file") {
            if (item.id > 0) {
              this.$nextTick(() => {
                this.$refs["input-item-" + item.id][0].focus();
              });
            }
          } else if (item.type == "folder") {
            this.openFolder(item);
          }
        }
        // }
      }
    },

    // Gestion des Fichiers
    async uploadFileAdded(upload) {
      try {
        let refresh = false;
        let file = upload;
        let formData = new FormData();
        formData.append("file", file);
        if (this.fromModule == "orderFormProvider") {
          let formData = new FormData();
          formData.append("file", file);
          await this.uploadFileOrderFormProvider({
            formData: formData,
            orderFormProviderId: this.orderFormProvider.id,
            institutionId: this.orderFormProvider.institutionId,
          });
          if (!this.parentFolder) {
            this.parentFolder = this.orderFormProvider.folderId;
          }
          await this.getFolderById({
            folderId: this.parentFolder,
          });
        } else if (this.fromModule == "receiptFormProvider") {
          let formData = new FormData();
          formData.append("file", file);
          await this.uploadFileReceiptFormProvider({
            formData: formData,
            receiptFormProviderId: this.receiptFormProvider.id,
            institutionId: this.receiptFormProvider.institutionId,
          });
          if (!this.parentFolder) {
            this.parentFolder = this.receiptFormProvider.folderId;
          }
          await this.getFolderById({
            folderId: this.parentFolder,
          });
        } else if (this.fromModule == "deliveryForm") {
          let formData = new FormData();
          formData.append("file", file);
          await this.uploadFileReceiptFormProvider({
            formData: formData,
            deliveryFormId: this.deliveryForm.id,
            institutionId: this.deliveryForm.institutionId,
          });
          if (!this.parentFolder) {
            this.parentFolder = this.deliveryForm.folderId;
          }
          await this.getFolderById({
            folderId: this.parentFolder,
          });
        } else if (this.fromModule == "inventory") {
          let formData = new FormData();
          formData.append("file", file);
          await this.uploadFileInventory({
            formData: formData,
            inventoryId: this.inventory.id,
          });
          if (!this.parentFolder) {
            this.parentFolder = this.inventory.folderId;
          }
          await this.getFolderById({
            folderId: this.parentFolder,
          });
        } else if (this.fromModule == "quote") {
          let formData = new FormData();
          formData.append("file", file);
          await this.uploadFileQuote({
            formData: formData,
            quoteId: this.document.id,
          });
          if (!this.parentFolder) {
            this.parentFolder = this.document.folderId;
          }
          await this.getFolderById({
            folderId: this.parentFolder,
          });
        } else if (this.fromModule == "invoice") {
          let formData = new FormData();
          formData.append("file", file);
          await this.uploadFileInvoice({
            formData: formData,
            invoiceId: this.document.id,
            institutionId: this.document.institutionId,
          });
          if (!this.parentFolder) {
            this.parentFolder = this.document.folderId;
          }
          await this.getFolderById({
            folderId: this.parentFolder,
          });
        } else if (this.fromModule == "credit") {
          let formData = new FormData();
          formData.append("file", file);
          await this.uploadFileCredit({
            formData: formData,
            creditId: this.document.id,
            institutionId: this.document.institutionId,
          });
          if (!this.parentFolder) {
            this.parentFolder = this.document.folderId;
          }
          await this.getFolderById({
            folderId: this.parentFolder,
          });
        } else if (this.fromModule == "orderFormCustomer") {
          let formData = new FormData();
          formData.append("file", file);
          await this.uploadFileOrderFormCustomer({
            formData: formData,
            orderFormCustomerId: this.orderFormCustomer.id,
            institutionId: this.orderFormCustomer.institutionId,
          });
          if (!this.parentFolder) {
            this.parentFolder = this.orderFormCustomer.folderId;
          }
          await this.getFolderById({
            folderId: this.parentFolder,
          });
        }
      } catch (error) {
        console.error(error);
      }
      this.$refs.imgDropZone.removeFile(upload);
    },
    recup_extension(url) {
      if (url != "") {
        // si le champ fichier n'est pas vide
        url.replace();
        let nbchar = url.length; // on compte le nombre de caractere que compose ce chemin
        let extension = url.substring(nbchar - 4, nbchar); // on récupere les 4 derniers caracteres
        extension = extension.toLowerCase(); //on uniforme les caracteres en minuscules au cas ou cela aurait été écris en majuscule...
        return extension; // on renvoi l'extension vers la fonction appelante
      }
    },
    bgImage(url) {
      if (url !== "") {
        let typeFile = this.recup_extension(url).replace(".", "");
        try {
          import("@/assets/icones/" + typeFile + "-color.svg");

          return (
            "background-image:url(" +
            require("@/assets/icones/" + typeFile + "-color.svg") +
            "); background-size: auto 100%; background-position:center; width: 100%; height: 150px;"
          );
        } catch (error) {
          return (
            "background-image:url(" +
            require("@/assets/icones/unknow-color.svg") +
            "); background-size: auto 100%; background-position:center; width: 100%; height: 150px;"
          );
        }
      } else {
        return (
          "background-image:url(" +
          require("@/assets/icones/unknow-color.svg") +
          "); background-size: auto 100%; background-position:center; width: 100%; height: 150px;"
        );
      }
    },
    bgImageSmall(url) {
      if (url !== "") {
        let typeFile = this.recup_extension(url).replace(".", "");
        try {
          import("@/assets/icones/" + typeFile + "-color.svg");

          return (
            "background-image:url(" +
            require("@/assets/icones/" + typeFile + "-color.svg") +
            "); background-size: auto 100%; background-position:center; ; width: 30px; height: 30px; position: relative"
          );
        } catch (error) {
          return (
            "background-image:url(" +
            require("@/assets/icones/unknow-color.svg") +
            "); background-size: auto 100%; background-position:center; ; width: 30px; height: 30px; position: relative"
          );
        }
      } else {
        return (
          "background-image:url(" +
          require("@/assets/icones/unknow-color.svg") +
          "); background-size: auto 100%; background-position:center; ; width: 30px; height: 30px; position: relative"
        );
      }
    },
    bgFolderImg(img) {
      return (
        "background-image:url(" +
        img.replace("Ged\\", "Ged/") +
        "); background-position: center; background-size: cover;height: 30px;width:30px; background-repeat: no-repeat; "
      );
    },
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },

    // Gestion des Dossiers
    async breadcrumbFolder() {
      let _this = this;
      let list = [];
      function traverse(node, path) {
        if (!path) path = [];
        if (node.name) {
          let folderName =
            node.name.toLowerCase() ==
            _this.workspaceSelected.uniqueIdentifier.toLowerCase()
              ? _this.workspaceSelected.socialReason
              : node.name;
          let data = { name: folderName, id: node.id };
          path.push(data);
        }
        list.push(path);
        if (node.subFolders) {
          node.subFolders.forEach(function (item) {
            traverse(item, path.slice());
          });
        }
      }
      traverse(this.foldersList, []);
      this.breadcrumbFolderList = list.find((elem) =>
        elem.find((elem) => elem.id == this.folder.id)
      );
    },
    popupEditFile(id) {
      this.$bvModal.show("modal-edit-file");
      this.$nextTick(() => {
        this.$refs["popupEditFile"].popupEditFileEvent(id);
      });
      return false;
    },
    popupEditFolder(id) {
      this.$bvModal.show("modal-edit-folder");
      this.$nextTick(() => {
        this.$refs["popupEditFolder"].popupEditFolderEvent(id);
      });
      return false;
    },
    async openFolder(element, event) {
      this.editItem(0);
      await this.getFoldersTreeById(this.parentFolder);
      await this.breadcrumbFolder();
      await this.getFolderById({
        folderId: element.id,
      });
      this.breadcrumbFolder();
    },
    deleteFileForm(item) {
      this.deleteFiles({
        fileIds: [item.id],
      });
      this.editItem(0);
    },
    setItemFileHover(index) {
      this.folder.files[index].hover = true;
    },
    sizeFormat(number) {
      let size = Math.ceil(number / 1000);
      if (size > 1000) {
        return (size / 1000).toFixed(2).replace(",", ".") + " Mo";
      } else {
        return size + " Ko";
      }
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
<style lang="scss">
.vue-dropzone {
  border: dashed 2px #d8d6de !important;
  border-radius: 0.5rem !important;
  background: transparent !important;
  height: 100%;
}

#gedViewer {
  display: flex;
  flex-direction: column;
  .dropzone.dz-clickable {
    // cursor: pointer;
    // position: absolute;
    // height: 100%;
    // margin: 0px 10px;
    // width: calc(100% - 20px);
  }
  .finder {
    margin-bottom: 1rem;
    gap: 10px;
    .finder-title {
      display: flex;
      justify-content: space-between;
    }
    .finder-list {
      .table {
        padding-top: 0px;
        margin-top: -12px;
        thead > tr {
          background: transparent !important;
          border-top: 1px solid #ccc;
          th {
            background: transparent !important;
            padding: 0.5rem 1rem;
            font-size: 12px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.54);
            font-style: italic;
            text-transform: none;
            letter-spacing: normal;
            cursor: pointer;
            &.asc:after {
              content: "\25B2";
            }
            &.desc:after {
              content: "\25BC";
            }
          }
        }
        tr {
          &.rowHover {
            .rowHoverElement {
              display: none;
            }
            &:hover {
              .rowHoverElement {
                display: block;
              }
            }
          }
          &.row-selected {
            background: #dae6fe !important;
            .cell-type {
              display: flex;
              align-content: center;
              justify-content: center;
              .fake-checkbox {
                background: #dae6fe;
                border-radius: 0;
                width: 30px;
                height: 30px;
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                .checked {
                  position: relative;
                  font-size: 10px;
                  width: 16px;
                  height: 16px;
                  border-radius: 2px;
                  border: #333 2px solid;
                  background: #333;
                  box-shadow: 0 0 3px rgb(0 0 0 / 19%);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  align-content: center;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  .material-icons {
                    font-size: 14px;
                  }
                }
              }
            }
          }
          &:hover {
            background: #dae6fe !important;
            cursor: pointer;
            .fake-checkbox {
              background: #f1f1f190;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              display: flex;
              align-content: center;
              justify-content: center;
              align-items: center;
              .checked {
                position: relative;
                font-size: 10px;
                width: 16px;
                height: 16px;
                border-radius: 2px;
                border: #333 2px solid;
                background: transparent;
                box-shadow: 0 0 3px rgb(0 0 0 / 19%);
                cursor: pointer;
              }
            }
          }
          td {
            padding: 0.25rem 1rem;
            .cell-type {
              display: flex;
              align-content: center;
              justify-content: center;
              width: 30px;
              height: 30px;
              position: relative;
              max-height: 190px;
              margin: 0px !important;
              padding: 0px !important;
              text-align: center;
              /* center checkbox horizontally */
              vertical-align: middle;
              .folder-application {
                position: absolute;
                font-size: 10px;
                bottom: 0px;
                right: -2px;
              }
              .checkbox {
                display: flex;
                align-content: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                position: relative;
                align-items: center;
                flex-direction: row;
                padding: 0px !important;
                input {
                  margin: auto 100%;
                }
              }
            }
          }
        }
      }
    }
    .finder-content {
      margin-top: -12px;
      position:relative > div {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        .ged-grid-item-folder {
          display: flex;
          width: 200px;
          height: 50px;
          justify-content: space-between;
          align-items: center;
          border: solid 2px #e5e5e5;
          border-radius: 5px;
          padding: 10px;
          margin: 15px 15px 15px 0px;
          margin-top: 15px;
          overflow: hidden;
        }
        .ged-grid-item {
          display: flex;
          align-content: center;
          align-items: center;
          border-radius: 6px;
          border: 2px solid #dadce0;
          position: relative;
          cursor: pointer;
          .folder-application {
            position: absolute;
            bottom: 0px;
            right: 0px;
            font-size: 15px !important;
          }
          .material-icons {
            margin: 0.5rem;
            font-size: 32px;
            color: #98acce;
          }
          .name {
            margin: 0px;
            padding: 0px;
            font-weight: 500;
          }
          .file-hover {
            position: relative;
            width: 100%;
            &:hover {
              transition-duration: 0.3s;
              z-index: 1;
              .enableBlur {
                filter: blur(4px);
              }
            }
            .file-img {
              border-bottom: 1px solid #dadce0;
              div {
                -webkit-border-radius: 6px;
                border-radius: 6px;
              }
            }
            .file-text {
              overflow: hidden;
              max-height: 30px;
              line-height: 30px;
            }
            .file-tools {
              filter: blur(0px);
              position: absolute;
              top: 0px;
              z-index: 1;
              display: none;
              width: 100%;
              height: 100%;
              align-content: center;
              align-items: center;
              justify-content: center;
              flex-direction: column;
            }
            &:hover .file-tools {
              display: flex !important ;
            }
          }
        }
      }
      &:before {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        background: #ccc;
      }
    }
  }
}
</style>
<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  border: #bad0fb 2px dashed;
}
</style>

<template lang="pug">
  div(class="invoiceFooter")
    .row
      .col-sm-6.mt-1.invoiceFooterLeft
        PaymentConditions.mt-1(:preview="true")
        TotalTva.mt-2(v-show="!orderFormOption.hideTotal")
      div.col-sm-6.mt-2.invoiceFooterRight(v-show="!orderFormOption.hideTotal")
        TotalOrderFormProvider(@updateTvaDetails='updateTvaDetails' :canEditDiscount='true' :preview="true" ref="TotalOrderFormProvider")
    .print-footer.mb-1(ref="printFooter")
        span(v-html="orderFormProvider.footer")
</template>

<script>
import PaymentConditions from "@/components/purchase/order-form-provider/builder/PaymentConditions";
import TotalOrderFormProvider from "@/components/purchase/order-form-provider/builder/TotalOrderFormProvider";
import AddGlobalDiscount from "@/components/purchase/order-form-provider/builder/AddGlobalDiscount";
import TotalTva from "@/components/purchase/order-form-provider/builder/TotalTva";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tvaDetails: [],
      printFooterHeight: 0,
    };
  },
  computed: {
    ...mapGetters(["orderFormProvider", "orderFormOption"]),
  },
  methods: {
    updateTvaDetails(val) {
      this.tvaDetails = val;
    },
  },
  components: {
    PaymentConditions,
    TotalOrderFormProvider,
    AddGlobalDiscount,
    TotalTva,
  },
};
</script>

<template lang="pug">
    table.orderFormProviderDetails   
      thead
        OrderFormProviderPreviewHeader()
      tbody 
        OrderFormProviderPreviewDetails(v-for="(line, index) in getOrderFormProviderDetails" :key="index" :line="line" :index="index")
</template>
<script>
import OrderFormProviderPreviewHeader from "@/components/purchase/order-form-provider/preview/table/OrderFormProviderPreviewHeader.vue";
import OrderFormProviderPreviewDetails from "@/components/purchase/order-form-provider/preview/table/OrderFormProviderPreviewDetails.vue";
import { EDocumentTypeLine, EInputType } from "@/types/api-orisis/enums/enums";
import draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getOrderFormProviderDetails"]),
  },
  components: {
    OrderFormProviderPreviewHeader,
    OrderFormProviderPreviewDetails,
    draggable,
  },
};
</script>

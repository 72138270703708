<template lang="pug">
div(v-if="preview && documentPaymentMethods.length>0")
  span.d-flex(v-if="documentPaymentMethods.length>0" style='flex-wrap: wrap;')
    span(style="margin-right:5px") Méthode{{ documentPaymentMethods.length>1 ? 's' : '' }} de paiement :
    span(style="margin-right:5px" v-for="(item, index) in documentPaymentMethods", :key="index") {{ paymentMethodTranslate(item) }}{{ documentPaymentMethods.length - 1 == index ? '.' : ',' }}
div(v-else-if="!preview")
  div(v-if="paymentMethodsList.length>0" style="margin-bottom:5px")
    span.d-flex(style='flex-wrap: wrap;') Méthode{{ documentPaymentMethods.length>1 ? 's' : '' }} de paiement :
      .span(v-for="(item, index) in documentPaymentMethods", :key="index") &nbsp {{ paymentMethodTranslate(item) }}{{ documentPaymentMethods.length - 1 == index ? '.' : ',' }}
      span.no-printting.text-primary.ml-1.cursor-pointer(@click="edit=!edit") {{ !edit ? "+ Ajouter" : "- Masquer"}}
    b-form-group.payment-methods-config(v-if="edit")
      b-form-checkbox(v-for='option in paymentMethodsList' @change="chooseOption" :key='option.id' v-model='documentPaymentMethods' :value='option.id')
        | {{ option.label }}

</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { BFormCheckboxGroup } from "bootstrap-vue";
import { paymentMethodTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  props: {
    preview: {
      default: false,
    },
  },
  data() {
    return {
      showTools: false,
      edit: false,
    };
  },
  computed: {
    ...mapGetters(["paymentMethodsList"]),
    document: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
    documentPaymentMethods: {
      get() {
        return this.$store.getters.orderFormProviderPaymentMethods;
      },
      set(value) {
        this.$store.commit("SET_ORDER_FORM_PAYMENT_METHODS", value);
      },
    },
  },
  async created() {
    if (!this.paymentMethodsList || this.paymentMethodsList.length == 0) {
      await this.getPaymentMethodByWorkspaceId({});
    }
  },
  methods: {
    ...mapActions([
      "updatePaymentMethodOrderFormProvider",
      "getPaymentMethodByWorkspaceId",
    ]),
    paymentMethodTranslate,
    chooseOption() {
      this.updatePaymentMethodOrderFormProvider({
        paymentMethodOrderFormProvider: this.documentPaymentMethods,
        orderFormProviderId: this.document.id,
      });
    },
  },
  components: {
    BFormCheckboxGroup,
  },
};
</script>

<style>
.payment-methods-config {
  padding: 5px;
}
</style>

<template lang="pug">
div
  div.content-span-document-detail(v-if="!orderFormProviderHeader[dataKey].editable")
    span(v-model="line[dataKey]") 
    span {{orderFormProviderHeader[dataKey].prefix }} 
    span {{orderFormProviderHeader[dataKey].numberToFixed ? (line[dataKey]).toFixed(orderFormProviderHeader[dataKey].numberToFixed) : line[dataKey]}}
    span {{orderFormProviderHeader[dataKey].unit}}
  input.w-100(:disabled="line.isDisabled" @focus="emitEditFocus(true)" @blur="blurFunction(false)" v-else :class="[edit && canEdit ? 'edit' : 'no-edit', (line.isDisabled) ? 'disabled' : '']" v-model="line[dataKey]")
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { productMergeByTypeLine } from "@/types/api-orisis/library/DetailOrderFormProviderOperations.ts";
import { calculateSubTotalLineBySection } from "@/types/api-orisis/library/OrderFormProviderOperations";

export default {
  data() {
    return {
      calculateSubTotalLineBySection,
    };
  },
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    orderFormProviderHeader: {
      type: Object,
      required: false,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      "productProviderSortedByProductType",
      "getOrderFormProviderDetails",
      "orderFormProvider",
    ]),
    orderFormProvider: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
  },
  methods: {
    productMergeByTypeLine,
    ...mapActions([
      "updateOrderFormProviderDetail",
      "updateOrderForm",
      "getCollaboratorById",
      "getCompanyById",
    ]),
    getDefaultAddressCompany(company) {
      return new Promise((resolve, reject) => {
        company.addresses.find((adress) => {
          if (adress.isDefault) {
            resolve(adress);
          }
        });
      });
    },
    async setSelectProvider(company) {
      if (company.id == null) {
        this.collaboratorInfo = null;
        this.orderFormProvider = {
          ...this.orderFormProvider,
          companyProviderId: company.id,
          companyProviderName: company.name,
          companyProviderAddress: company.address,
          companyProviderAddressComplement: company.addressComplement,
          companyProviderCity: company.city,
          companyProviderZipCode: company.zipCode,
          companyProviderCountry: company.country,
          companyProviderSiret: company.siret,
        };
        this.saveAction(false);
      } else {
        await this.getCompanyById({ companyId: company.id }).then(
          async (company) => {
            this.collaboratorInfo = company.collaboratorId
              ? await this.getCollaboratorById({
                  collaboratorId: company.collaboratorId,
                })
              : null;
            if (company.addresses && company.addresses.length > 0) {
              await this.getDefaultAddressCompany(company).then((res) => {
                this.orderFormProvider = {
                  ...this.orderFormProvider,
                  companyProviderId: company.id,
                  companyProviderName: company.name,
                  companyProviderAddress: res.address,
                  companyProviderAddressComplement: res.addressComplement,
                  companyProviderCity: res.city,
                  companyProviderZipCode: res.zipCode,
                  companyProviderCountry: res.country,
                  companyProviderSiret: res.siret,
                };
              });
            } else {
              this.orderFormProvider = {
                ...this.orderFormProvider,
                companyProviderId: company.id,
                companyProviderName: company.name,
                companyProviderSiret: company.siret,
                companyProviderAddress: null,
                companyProviderAddressComplement: null,
                companyProviderCity: null,
                companyProviderZipCode: null,
                companyProviderCountry: null,
              };
            }
          }
        );
        this.saveAction(false);
      }
    },
    saveAction(bool) {
      this.updateOrderForm({
        orderFormProvider: this.orderFormProvider,
        loading: bool,
      });
    },
    async affectValue(val) {
      //Si c'est le premier produit des détails, on affecte le fournisseur du produit au bon de commande
      if (
        !this.getOrderFormProviderDetails.find(
          (elem) => elem.productProviderId != null
        )
      ) {
        this.setSelectProvider(val.company);
      }
      this.line["description"] = val.description ? val.description : val.label;
      this.line["quantity"] = val.quantity ? val.quantity : 0;
      this.line["productProviderId"] = val.id;
      this.line["reference"] = val.reference ? val.reference : null;
      this.line["unitId"] = val.unit ? val.unit.id : this.line["unitId"];
      this.line["unitPriceHt"] = val.netPrice
        ? val.netPrice
        : this.line["unitPriceHt"];
      this.line["total"] = val.netPrice
        ? Math.round(this.line["quantity"] * val.netPrice * 100) / 100
        : this.line["total"];
      this.line["productId"] = val.productId ? val.productId : null;
      this.line["discount"] = val.discount ? val.discount : 0;
      this.line["label"] = val.label ? val.label : null;
      this.line["marginRate"] = val.marginRate ? val.marginRate : 0;
      this.line["ecoContribution"] = val.ecoContribution
        ? val.ecoContribution
        : 0;
      this.line["grossMarginHT"] = val.grossMarginHT ? val.grossMarginHT : 0;
      this.line["isDefault"] = val.isDefault;
      this.line["useDegressivePurchaseRate"] = val.useDegressivePurchaseRate
        ? val.useDegressivePurchaseRate
        : 0;
      this.line["purchaseCategoryId"] = val.purchaseCategoryId
        ? val.purchaseCategoryId
        : null;
      this.line["productType"] = val.productType ? val.productType : 0;
      this.line["netPrice"] = val.netPrice ? val.netPrice : 0;
      this.line["amountHt"] = val.amountHt ? val.amountHt : 0;

      switch (val.productType) {
        case 0:
          this.line["type"] = 7;
          break;
        case 2:
          this.line["type"] = 9;
          break;
        case 4:
          this.line["type"] = 6;
          break;
        case 5:
          this.line["type"] = 8;
          break;
      }
      await this.updateOrderFormProviderDetail({
        orderFormProviderDetail: this.line,
      });
      await this.calculateSubTotalLineBySection(this.line);
    },
    productProvidersListByTypeLine() {
      return this.productMergeByTypeLine(
        this.line,
        this.productProviderSortedByProductType,
        this.orderFormProvider.companyProviderId
      );
      // }
    },
    selectProduct() {
      if (
        this.dataKey == "reference" &&
        this.line[this.dataKey] &&
        this.line[this.dataKey].length > 0
      ) {
        let item = null;
        for (let propriete in this.productProvidersListByTypeLine()) {
          if (this.productProvidersListByTypeLine().hasOwnProperty(propriete)) {
            const tableau = this.productProvidersListByTypeLine()[propriete];
            const resultatTrouve = tableau.find(
              (element) => element.reference == this.line[this.dataKey]
            );
            if (resultatTrouve) {
              item = resultatTrouve;
              break;
            }
          }
        }
        if (item) {
          this.affectValue(item);
        }
      }
    },
    emitEditFocus(res) {
      this.$emit("emitEditFocus", res);
    },
    blurFunction(res) {
      this.selectProduct();
      this.emitEditFocus(res);
      this.updateOrderFormProviderDetail({
        orderFormProviderDetail: this.line,
      });
    },
  },
};
</script>

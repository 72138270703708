<template lang="pug">
    div(v-if="!preview")
      span {{text}}
          span(v-if="technicalVisitDate" @mouseover="showTools = true" @mouseleave="showTools = false") {{ begginingWorkDate }} 
              span.pl-1.cursor-pointer.bold(v-if="showTools && !disabled") 
                  feather-icon(icon="Edit3Icon" size="18"  @click="edit=true" v-b-tooltip.hover.top="'Modifier la date'") 
          a(v-if="technicalVisitDate == null" @click="edit = !edit") définir
          .select-date-tool.mt-1.mb-1(v-if="edit")
              .row
                  .col-md-7(id="colCalandar")
                      div
                          b-form-datepicker(v-model="technicalVisitDate" placeholder="" class="form-control"  id="example-datepicker" @input="edit = false"  locale="fr"   :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }")
                      .text-right
                          b-button(variant="outline-primary" @click="edit = false") Fermer
    div(v-else)
      div.d-flex
        span {{ text }} {{ begginingWorkDate }} 
</template>
<script>
import { BButton, BFormDatepicker } from 'bootstrap-vue'
import dayjs from 'dayjs'
export default {
  components: {
    BButton,
    BFormDatepicker
  },
  props: {
    preview: {
      default: false,
    },
    documentDetailKey:{
      type: String,
      default: null
    },
    text: {
      type: String,
      default: 'Date :'
    },
    keyValue: {
      type: String 
    },
    disabled: {
      default: false
    },
  },
  data () {
    return {
      edit: false,
      date: null,
      showTools: false,
    }
  },
  created() {
    if (this.document.nature == 0) {
      this.document.quoteCEE.technicalVisitDate = new Date();
    } else {
      this.document.invoiceCEE.technicalVisitDate = new Date();
    }
  },
  computed: {
    technicalVisitDate: {
      get() {
        if (this.document.nature == 0) {
          return this.document.quoteCEE.technicalVisitDate
        } else {
          return this.document.invoiceCEE.technicalVisitDate
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          this.document.quoteCEE.technicalVisitDate = value
        } else {
          this.document.invoiceCEE.technicalVisitDate = value
        }
      }
    },
    begginingWorkDate () {
      if (this.document.nature == 0) {
          return dayjs(this.document.quoteCEE.technicalVisitDate).format('DD/MM/YYYY')
        } else {
          return dayjs(this.document.invoiceCEE.technicalVisitDate).format('DD/MM/YYYY')
        }
    },
    document: {
      get() {
        return this.$store.getters.document; 
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      }
    },
  }
}
</script>
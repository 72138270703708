<template lang="pug">
div.builder-header.px-2(v-show="!isLoadingOrderFormProvider")
  div 
    ul#pills-tab.nav.nav-pill(role='tablist')
      li.nav-item(v-if="orderFormProvider.status < 3" @click="changeOrderFormProviderTabActive('order-form-provider-edit')")
        div.nav-link.cursor-pointer.text-center(:class="orderFormProviderTabActive == 'order-form-provider-edit' ? 'active' : ''")
          feather-icon(icon="Edit2Icon" size="15") 
          |  Edition
      li.nav-item(v-if="checkIfPreviewIsPossible()" @click="showPreviewPdf")
        div.nav-link.cursor-pointer.text-center(:class="orderFormProviderTabActive == 'order-form-provider-pdf' ? 'active' : ''")
          feather-icon(icon="EyeIcon" size="15") 
          |  Prévisualisation
      li.nav-item(v-if="haveGedModule" @click="changeOrderFormProviderTabActive('attachments')")
        div.nav-link.cursor-pointer.text-center(:class="orderFormProviderTabActive == 'attachments' ? 'active' : ''")
          feather-icon(icon="FolderIcon" size="15") 
          |  Documents
      li.nav-item(@click="changeOrderFormProviderTabActive('internal-note')")
        div.nav-link.cursor-pointer.text-center(:class="orderFormProviderTabActive == 'internal-note' ? 'active' : ''")
          feather-icon(icon="LockIcon" size="15") 
          |  Notes
  div.d-flex.align-items-center.ml-auto
    .form-group.form-check.mb-0.mr-1.tab-control.builderControls(v-if="orderFormProviderTabActive=='order-form-provider-edit'" data-tab='order-form-provider-edit')
      .d-flex.align-items-center
        b-dropdown.mr-1#dropdown-form(ref='dropdown' right='' variant='outline-secondary' text='Options')
          b-dropdown-form.dropdown-options
            h6 Edition
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormOption.showReferenceColumn")
              | Afficher la colonne des références
            b-form-checkbox.checkbox-options(@change="isReverseChargeRequest" v-model="orderFormOption.isReverseCharge")
              | Autoliquidation
            b-form-checkbox.checkbox-options(@change="discountLineAction" v-model="orderFormOption.showDiscountColumn")
              | Afficher la colonne des remises par ligne
            h6.mt-1 Prévisualisation
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.hideInstitutionLogo")
              | Masquer mon logo
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.hideInstitutionName")
              | Masquer ma raison sociale
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.hideInstitutionAddress")
              | Masquer mon adresse
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.hideAffair")
              | Masquer l'affaire
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.showAffairCode")
              | Masquer le code de l'affaire
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.hideStorageLocation")
              | Masquer le point de stockage
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.hideTotal")
              | Masquer le total
            h6.mt-1 Document
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.hideUser")
              | Masquer le contact
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormOption.hideIndexColumn")
              | Masquer la colonne des index
            //- b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormOption.hideQuantityColumn")
            //-   | Masquer la colonne des quantités
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormOption.hideUnitColumn")
              | Masquer la colonne des unités
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormOption.hideUnitPriceHtColumn")
              | Masquer la colonne des prix unitaire HT
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormOption.hideReferencielTvaColumn")
              | Masquer la colonne des TVA
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="orderFormOption.hidePriceHtColumn")
              | Masquer la colonne des totaux HT
            h6.mt-1 Client
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.hideCompanyCustomer")
              | Masquer le client
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.showCompanyCustomerCode")
              | Afficher le code du client
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.showCompanyCustomerAddressLabel")
              | Afficher le libellé de l'adresse
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.showCompanyCustomerMail")
              | Afficher l'adresse mail
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.showCompanyCustomerPhoneNumber")
              | Afficher le numéro de téléphone
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.showCompanyCustomerSiret")
              | Afficher le numéro de SIRET
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.showCompanyCustomerTvaNumber")
              | Afficher le numéro TVA intracommunautaire
            h6.mt-1 Fournisseur
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.hideCompanyProvider")
              | Masquer le fournisseur
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.showCompanyProviderCode")
              | Afficher le code du fournisseur
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.showCompanyProviderAddressLabel")
              | Afficher le libellé de l'adresse
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.showCompanyProviderMail")
              | Afficher l'adresse mail
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.showCompanyProviderPhoneNumber")
              | Afficher le numéro de téléphone
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.showCompanyProviderSiret")
              | Afficher le numéro de SIRET
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="orderFormOption.showCompanyProviderTvaNumber")
              | Afficher le numéro de TVA intracommunautaire
        .vl
    div(v-if="orderFormProvider.status == 0 || orderFormProvider.status == 1")
      b-button.mr-1.btn-invoice-builder-header(@click="cancel" variant='outline-danger')
        | Supprimer
    ejs-tooltip.tooltipcontainer(opensOn='Custom' v-on:mouseover.native='customOpen' v-on:mouseleave.native='customClose' content="Certaines lignes ne sont pas valides" :cssClass="cssClass" ref='tooltip')
      div.d-flex.align-items-center.ml-auto#test(v-if="(orderFormProvider.status < 4) && orderFormProviderDetails.length > 0")
        b-button-group.mr-1
          b-dropdown.dropdown-options(text='Finaliser le document' variant='success' dropup :disabled="productListIncomplete")
            b-dropdown-item.cursor-pointer(@click="finalizedDocument(1, false)" :disabled="orderFormProvider.status >= 2" :style="orderFormProvider.status >= 2 ? 'opacity:0.5' : ''") 
              span.chip.justify-content-start
                span.point.bg-warning
                span.label.text-warning Passer en validation
            b-dropdown-item.cursor-pointer(@click="finalizedDocument(2, false)" :disabled="orderFormProvider.status >= 3" :style="orderFormProvider.status >= 3 ? 'opacity:0.5' : ''") 
              span.chip.justify-content-start
                span.point.bg-primary
                span.label.text-primary Valider la commande
            b-dropdown-item.cursor-pointer(@click="finalizedDocument(3, false)" :disabled="orderFormProvider.status >= 4" :style="orderFormProvider.status >= 4 ? 'opacity:0.5' : ''") 
              span.chip.justify-content-start
                span.point.bg-primary
                span.label.text-primary Passer sur ordre
    div
      b-button-group(v-if="orderFormProvider.status < 3")
        b-button.btn-invoice-builder-header.m-0(@click="checkStatusBeforeSave(false)" variant='primary')
          | Enregistrer
        b-button.btn-invoice-builder-header.bl-white(@click="checkStatusBeforeSave(true)" style="border-left: 1px solid #ccc !important;" variant='primary')
          feather-icon(icon='LogOutIcon')
      b-button-group(v-else-if="orderFormProvider.path")
        b-button.btn-invoice-builder-header.m-0(variant='primary' @click="forceFileDownload(orderFormProvider.path)")
          | Télécharger
          feather-icon.ml-50(icon='DownloadIcon')
        b-button.btn-invoice-builder-header.bl-white(variant='primary' @click="cancel" style="border-left: 1px solid #f1f1f1 !important; border-top-left-radius:0px; border-bottom-left-radius:0px; ")
          | Fermer 
          feather-icon.ml-50(icon='LogOutIcon')
      b-button.btn-invoice-builder-header(v-else variant='primary' @click="cancel")
        | Fermer 
        feather-icon.ml-50(icon='LogOutIcon')
</template>

<script>
import {
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { calculateTotalOrderFormProviderLine } from "@/types/api-orisis/library/DetailOrderFormProviderOperations";
import {
  executeMultipleAsyncFunctions,
  checkMandatoryInformation,
} from "@/types/api-orisis/library/OrderFormProviderOperations";
import { checkFunctionUser } from "@/auth/utils.ts";

export default {
  data() {
    return {
      calculateTotalOrderFormProviderLine,
      cssClass: "tool-tip-error",
    };
  },
  created() {
    this.setupHeaderOrderFormProvider();
  },
  methods: {
    ...mapActions([
      "updateOrderFormOption",
      "updateOrderForm",
      "deleteOrderFormProviders",
      "setupHeaderOrderFormProvider",
      "loopOrderFormProviderDetails",
      "changeOrderFormProviderTabActive",
      "updateOrderFormProviderDetail",
    ]),
    checkFunctionUser,
    isReverseChargeRequest(value) {
      if (value) {
        this.$bvModal
          .msgBoxConfirm(
            "Attention, toutes les TVA du document seront passées à 0 %.",
            {
              title: "Activer l'autoliquidation",
              size: "sm",
              okVariant: "primary",
              okTitle: "Oui",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.saveAction(false);
              this.loopOrderFormProviderDetails(5);
            } else {
              this.orderFormOption.isReverseCharge = false;
            }
          });
      } else {
        this.orderFormOption.isReverseCharge = false;
      }
    },
    executeMultipleAsyncFunctions,
    checkMandatoryInformation,
    customOpen(e) {
      if (this.productListIncomplete) this.$refs.tooltip.open(e.target);
    },
    customClose() {
      if (this.productListIncomplete) this.$refs.tooltip.close();
    },
    async passOrderDocument(status) {
      this.$bvModal
        .msgBoxConfirm(
          "Attention, en passant votre commande fournisseur en sous ordre, elle ne sera plus modifiable. N'oubliez pas de la transmettre à votre fournisseur.",
          {
            title: status == 2 ? "Valider la commande" : "Passer sur-ordre",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui, confirmer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            this.$store.commit("SET_IS_VALIDATING_ORDER_FORM", true);
            if (status == 2) {
              await this.updateOrderForm({
                orderFormProvider: {
                  ...this.orderFormProvider,
                  status: 3,
                },
              })
                .then(async () => {
                  if (
                    this.orderFormProviderTabActive == "order-form-provider-pdf"
                  ) {
                    this.$emit("changePreviewKey");
                  } else {
                    await this.changeOrderFormProviderTabActive(
                      "order-form-provider-pdf"
                    );
                  }
                  this.$store.commit("SET_IS_VALIDATING_ORDER_FORM", false);
                })
                .catch((err) => {
                  console.error(err);
                  this.$store.commit("SET_IS_VALIDATING_ORDER_FORM", false);
                });
            } else if (status == 3) {
              await this.updateOrderForm({
                orderFormProvider: {
                  ...this.orderFormProvider,
                  status: 4,
                },
              })
                .then(async () => {
                  if (
                    this.orderFormProviderTabActive == "order-form-provider-pdf"
                  ) {
                    this.$emit("changePreviewKey");
                  } else {
                    await this.changeOrderFormProviderTabActive(
                      "order-form-provider-pdf"
                    );
                  }
                  this.$store.commit("SET_IS_VALIDATING_ORDER_FORM", false);
                })
                .catch((err) => {
                  console.error(err);
                  this.$store.commit("SET_IS_VALIDATING_ORDER_FORM", false);
                });
            }
          }
        });
    },
    async finalizedDocument(status) {
      await this.checkMandatoryInformation()
        .then(async () => {
          let list = this.$store.getters.getOrderFormProviderDetails.filter(
            (el) =>
              (el.productProviderId == null || el.productProviderId == 0) &&
              (el.type == 7 || el.type == 8 || el.type == 9 || el.type == 10)
          );

          // if (list.length > 0) {
          //   this.$bvModal
          //     .msgBoxConfirm(
          //       `Attention, certains produits fournisseur n'existent pas dans votre catalogue, ils s'ajouteront automatiquement à votre catalogue de produit fournisseur. L'ajout de nouveaux produits implique la création ${
          //         list.length === 1 ? "d'une " : "de " + list.length
          //       } ${
          //         list.length > 1
          //           ? "nouvelles références"
          //           : "nouvelle référence"
          //       }. Voulez-vous continuer ?`,
          //       {
          //         title: "Produits fournisseur manquants",
          //         size: "sm",
          //         okVariant: "primary",
          //         okTitle: "Oui, confirmer",
          //         cancelTitle: "Annuler",
          //         cancelVariant: "outline-primary",
          //         hideHeaderClose: true,
          //         centered: true,
          //       }
          //     )
          //     .then(async (value) => {
          //       if (value) {
          //         await this.executeMultipleAsyncFunctionsValidation(
          //           list,
          //           status
          //         ).then((res) => {
          //           if (
          //             this.orderFormProviderTabActive ==
          //             "order-form-provider-pdf"
          //           ) {
          //             this.$emit("changePreviewKey");
          //           } else {
          //             this.changeOrderFormProviderTabActive(
          //               "order-form-provider-pdf"
          //             );
          //           }
          //         });
          //       }
          //     });
          // } else {

          //   await this.executeMultipleAsyncFunctionsValidation(
          //     list,
          //     status
          //   ).then(() => {

          //     if (
          //       this.orderFormProviderTabActive == "order-form-provider-pdf"
          //     ) {
          //       this.$emit("changePreviewKey");
          //     } else {
          //       this.changeOrderFormProviderTabActive(
          //         "order-form-provider-pdf"
          //       );
          //     }
          //   });
          // }
          await this.passOrderDocument(status);

          // await this.updateOrderForm({
          //   orderFormProvider: {
          //     ...this.orderFormProvider,
          //     status: status === 1 ? 2 : status,
          //   },
          // });
          // if (status == 1) {
          //   await this.updateOrderForm({
          //     orderFormProvider: {
          //       ...this.orderFormProvider,
          //       status: 2,
          //     },
          //   });
          //   if (this.orderFormProviderTabActive == "order-form-provider-pdf") {
          //     this.$emit("changePreviewKey");
          //   } else {
          //     await this.changeOrderFormProviderTabActive(
          //       "order-form-provider-pdf"
          //     );
          //   }
          // } else if (status == 2 || status == 3) {
          //   let list = this.$store.getters.getOrderFormProviderDetails.filter(
          //     (el) =>
          //       (el.productProviderId == null || el.productProviderId == 0) &&
          //       (el.type == 7 || el.type == 8 || el.type == 9 || el.type == 10)
          //   );
          //   if (list.length > 0) {
          //     this.$bvModal
          //       .msgBoxConfirm(
          //         "Attention, certains produits fournisseur n'existent pas dans votre catalogue, ils s'ajouteront automatiquement. Voulez-vous continuer ?",
          //         {
          //           title: "Produits fournisseur manquants",
          //           size: "sm",
          //           okVariant: "primary",
          //           okTitle: "Oui, confirmer",
          //           cancelTitle: "Annuler",
          //           cancelVariant: "outline-primary",
          //           hideHeaderClose: true,
          //           centered: true,
          //         }
          //       )
          //       .then(async (value) => {
          //         if (value) {
          //           this.executeMultipleAsyncFunctionsValidation(list, status);
          //         }
          //       });
          //   } else {
          //     this.executeMultipleAsyncFunctionsValidation(list, status);
          //   }
          // }
        })
        .catch(async (err) => {
          await this.$swal({
            title: err,
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: "Corriger",
            customClass: {
              confirmButton: "btn btn-outline-danger",
            },
            buttonsStyling: false,
          });
        });
    },
    executeMultipleAsyncFunctionsValidation(list, status) {
      return new Promise((resolve, reject) => {
        this.executeMultipleAsyncFunctions(list)
          .then(async () => {
            // Finaliser
            await this.passOrderDocument(status);
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },

    showPreviewPdf() {
      this.$emit("changePreviewKey");
      this.$nextTick(() => {
        this.changeOrderFormProviderTabActive("order-form-provider-pdf");
      });
    },

    saveAction(bool) {
      this.updateOrderFormOption({
        orderFormOption: this.orderFormOption,
      });
    },

    async checkStatusBeforeSave(exit) {
      await this.updateOrderForm({
        orderFormProvider: {
          ...this.orderFormProvider,
          status:
            this.orderFormProvider.status == 0
              ? 1
              : this.orderFormProvider.status,
        },
      });
      if (exit) {
        this.$tabs.close({ to: "/order-form-providers" });
      }
    },
    checkIfPreviewIsPossible() {
      if (this.orderFormProvider.companyProviderId) {
        return true;
      } else {
        return false;
      }
    },
    forceFileDownload(donwload) {
      // TODO : ATTENTION FONCTIONNE PAS LOCAL, EXIGE CORS UNCLOCK
      axios({
        url: donwload,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        var fullUri = document.createElement("a");
        fullUri.href = fileURL;
        fullUri.setAttribute(
          "download",
          this.orderFormOption.documentReference
        );
        document.body.appendChild(fullUri);
        fullUri.click();
        URL.revokeObjectURL(fullUri.href);
      });
    },
    async setHeader() {
      this.setupHeaderOrderFormProvider();
      this.saveAction(false);
    },
    async discountLineAction() {
      await this.setHeader();
      if (!this.orderFormProvider.showDiscountColumn) {
        for (let i = 0; i < this.orderFormProviderDetails.length; i++) {
          const orderFormProviderDetail = this.orderFormProviderDetails[i];
          if (orderFormProviderDetail.discount > 0) {
            orderFormProviderDetail.discount = 0;
            let total = this.calculateTotalOrderFormProviderLine(
              orderFormProviderDetail
            );
            this.updateOrderFormProviderDetail({
              orderFormProviderDetail: {
                ...orderFormProviderDetail,
                total: total,
              },
            });
          }
        }
      }
    },
    isReverseChargeRequest(value) {
      if (value) {
        this.$bvModal
          .msgBoxConfirm(
            "Attention, toutes les TVA du document seront passées à 0 %.",
            {
              title: "Activer l'autoliquidation",
              size: "sm",
              okVariant: "primary",
              okTitle: "Oui",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.saveAction(false);
              this.loopOrderFormProviderDetails(5);
            } else {
              this.orderFormOption.isReverseCharge = false;
            }
          });
      } else {
        this.orderFormOption.isReverseCharge = false;
      }
    },
    cancel() {
      if (this.orderFormProvider.status !== 0) {
        this.closeTab();
      } else {
        this.$bvModal
          .msgBoxConfirm(
            `La commande fournisseur n'ayant aucun statut, vous êtes sur le point de la supprimer. Attention, cette action est irreversible.`,
            {
              title:
                "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
              size: "sm",
              okVariant: "danger",
              okTitle: "Supprimer",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              if (this.orderFormProvider.status == 0) {
                this.deleteOrderFormProviders({
                  orderFormProviderIds: [this.orderFormProvider.id],
                }).then(() => {
                  this.closeTab();
                });
              } else {
                this.closeTab();
              }
            }
          });
      }
    },
    closeTab() {
      if (
        this.$route.params.routeOrigine &&
        this.$route.params.routeOrigine !== ""
      ) {
        this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
      } else {
        this.$tabs.close();
      }
    },
  },
  computed: {
    ...mapGetters([
      "isValidatingOrderFormProvider",
      "isUpdatingOrderFormProvider",
      "orderFormProviderTabActive",
      "orderFormProviderPDF",
      "isLoadingOrderFormProvider",
      "isLoadingOrderFormProviderPDF",
      "workspaceSelected",
    ]),
    orderFormOption: {
      get() {
        return this.$store.getters.orderFormOption;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_OPTION", value);
      },
    },
    orderFormProvider: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
    orderFormProviderDetails: {
      get() {
        return this.$store.getters["getOrderFormProviderDetails"];
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_DETAILS", value);
      },
    },
    haveGedModule() {
      return (
        this.checkFunctionUser(42, "all") && this.checkFunctionUser(43, "all")
      );
    },
    productListIncomplete() {
      for (let i = 0; i < this.orderFormProviderDetails.length; i++) {
        const orderFormProviderDetail = this.orderFormProviderDetails[i];
        if (
          // (!orderFormProviderDetail.productId ||
          //   !orderFormProviderDetail.productProviderId ||
          // !orderFormProviderDetail.reference ||
          !orderFormProviderDetail.description &&
          // )
          [7, 8, 9, 10].includes(orderFormProviderDetail.type)
        ) {
          return true;
        }
      }
      return false;
    },
  },
  components: {
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BButton,
    BDropdownDivider,
  },
  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
.vl {
  border-left: 1px solid #8a8e93;
  width: 1px;
  height: 16px;
  background: rgb(255, 255, 255);
}

.btn-invoice-builder-header {
  padding: 8px;
  height: 38px;
}

.bl-white {
  border-left-color: red !important;
}

.checkbox-options {
  margin-bottom: 10px;
}

.checkbox-options > label {
  font-size: 13px !important;
}
</style>

<template lang="pug"> 
  BreakPageCol(:line="line" :dataKey="dataKey" :orderFormProviderHeader="invoiceHeader" :canEdit="true" :edit="edit" :preview="true")      
</template>

<script>
import BreakPageCol from "@/components/purchase/order-form-provider/builder/table/cols/BreakPageCol.vue";

export default {
  data() {
    return {};
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    orderFormProviderHeader: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
  },
  components: {
    BreakPageCol,
  },
};
</script>

<template lang="pug">
div 
    ejs-dialog(id="dialog" ref="templateDialog" :header="headerTemplate" :content="contentTemplate" :footerTemplate="footerTemplate" width="500" :showCloseIcon="true" :close="toggleActive" v-if="isActiveModalEditRefProduct")
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

var headerTemplate = Vue.component("HeaderTemplate", {
  template:
    '<p class="m-0" v-if="!productProviderExisting">La produit fournisseur <span style="font-weight: 700">{{copy.reference}}</span> n\'existe pas.</p><p v-else class="m-0">Aucun produit de vente n\'est associé</p>',
  data() {
    return {
      copy: {
        reference: null,
      },
    };
  },
  computed: {
    ...mapGetters(["productToCreate", "productProviderExisting"]),
  },
  mounted() {
    this.copy = structuredClone(this.$store.getters["productToCreate"]);
  },
});

let contentTemplate = Vue.component("ContentTemplate", {
  template: `<div>
        <p class="mt-1" v-if="!productProviderExisting">Attention, la référence <span style="font-weight: 700">{{copy.reference}}</span> n'existe pas dans votre catalogue produit fournisseur.</p>
        <p class="mt-1" v-else>Attention, la référence <span style="font-weight: 700">{{copy.reference}}</span> n'a pas de produit de vente associé.</p>
        <b-form-checkbox class="my-1" v-model="linkingProduct" > Lier le produit fournisseur à un produit de vente ? </b-form-checkbox>
        <div v-show="linkingProduct">
            <p class="mt-1">Choisissez le produit de vente à lier :</p>
            <ejs-dropdownlist :dataSource="dataSource" :fields="{ text: 'label', value: 'id' }" v-model="productToCreate.productId"></ejs-dropdownlist>
        </div>
        <div v-show="!linkingProduct">
          <p>La création de ce produit fournisseur engendrera automatiquement la création d'un produit de vente dans votre catalogue de vente, portant la référence suivante :</p>
          <b-form-input :input="verifRefProductIsSingle(this.refToProduct)" placeholder="Référence" v-model="refToProduct" class="danger" style="margin-bottom: 5px" :state="!isExistingProduct"/>
          <small class="text-danger" v-if="!isCheckReference">Attention, veuillez compléter la référence.</small>
          <small class="text-danger" v-else-if="isExistingProduct">Attention cette référence existe déjà dans votre catalogue de produit de vente, celle-ci doit être unique.</small>
          <b-form-checkbox class="mt-1" v-model="productToCreate.unAvailableForSale" > Indisponible à la vente </b-form-checkbox>
        </div>
    </div>`,
  data() {
    return {
      copy: {
        reference: null,
      },
      editMode: false,
      editorOption: {
        placeholder: "Description...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
    };
  },
  created() {
    this.$store.commit("SET_REF_TO_PRODUCT", this.productToCreate.reference);
  },
  computed: {
    ...mapGetters([
      "productToCreate",
      "isExistingProduct",
      "isCheckReference",
      "catalog",
      "productProviderExisting",
    ]),
    dataSource() {
      return this.catalog;
    },
    linkingProduct: {
      get() {
        return this.$store.getters["isLinkingProduct"];
      },
      set(value) {
        return this.$store.commit("SET_IS_LINKING_PRODUCT", value);
      },
    },
    refToProduct: {
      get() {
        return this.$store.getters["refToProduct"];
      },
      set(value) {
        return this.$store.commit("SET_REF_TO_PRODUCT", value);
      },
    },
  },
  mounted() {
    this.copy = structuredClone(this.$store.getters["productToCreate"]);
  },
  methods: {
    ...mapActions(["verifRefProductIsSingle"]),
  },
});

let footerTemplate = Vue.component("FooterTemplate", {
  template: `<ejs-button class="e-primary" :disabled="!isCheckReference && isExistingProduct && !productToCreate.description" v-on:click.native="addProduct">Créer</ejs-button>`,
  computed: {
    ...mapGetters([
      "isExistingProduct",
      "isLinkingProduct",
      "isCheckReference",
    ]),
  },
  methods: {
    ...mapActions([
      "copyProductProviderForProduct",
      "linkProductToProductProvider",
      "productToCreate",
    ]),
    async addProduct() {
      if (this.isLinkingProduct) {
        await this.linkProductToProductProvider(
          this.$router.history.current.name === "new-receipt-form-provider" ||
            this.$router.history.current.name === "edit-receipt-form-provider"
            ? "updateReceiptFormProviderDetail"
            : "updateOrderFormProviderDetail"
        );
      } else {
        await this.copyProductProviderForProduct(
          this.$router.history.current.name === "new-receipt-form-provider" ||
            this.$router.history.current.name === "edit-receipt-form-provider"
            ? "updateReceiptFormProviderDetail"
            : "updateOrderFormProviderDetail"
        );
      }
    },
  },
});

export default {
  data() {
    return {
      headerTemplate: function () {
        return { template: headerTemplate };
      },
      contentTemplate: function () {
        return { template: contentTemplate };
      },
      footerTemplate: function () {
        return { template: footerTemplate };
      },
    };
  },
  computed: {
    ...mapGetters(["isActiveModalEditRefProduct", "productToCreate"]),
  },
  methods: {
    toggleActive(e) {
      e.event.preventDefault();
      this.$store.commit("SET_IS_ACTIVE_MODAL_EDIT_REF_PRODUCT", false);
    },
  },
};
</script>
<style lang="scss"></style>

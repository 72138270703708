<template lang="pug">
  .quote-card(v-if="!preview")
    b-form-group(v-if="!document.companyCustomerId" label-for='customer')
      validation-provider(#default='{ errors }' name='Client')
        v-select#company(ref="selectCompany" :loading='isLoadingCompaniesList' :state='errors.length > 0 ? false : null' :value='document.companyCustomerName' @input='setValue' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options='options.map((elem)=>{return {label:elem.name, value:elem.id}})' :placeholder='placeholder')
          template(v-slot:no-options='')
            template  Aucun r&eacute;sultat trouv&eacute;
          li.border-bottom.p-1.py-50(slot='list-header')
            b-button.w-100.d-flex.justify-content-center.align-items-center(@click='newCustomer()' variant='primary' size='sm')
              | + Nouveau client
              span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
                | open_in_new
        small.text-danger {{ errors[0] }}
    div(v-if="editMode")
      div.pb-1.d-flex(:class="editMode ? 'justify-content-between' : ''")
        span.text-primary.font-weight-bold Client lié :
        span.cursor-pointer.text-primary(v-if="editMode" @click='validEditMode') Enregistrer
      div.mb-1
        b-form-group(label-for='companyName' style='flex:1')
          validation-provider(#default='{ errors }' name='companyName')
            b-form-input(placeholder="Nom de la société" v-model="documentEdit.companyCustomerName")
      //- div.mb-1.d-flex
      //-   div.w-50
      //-     b-form-group.pr-1(label-for='companyFirstName' style='flex:1')
      //-       validation-provider(#default='{ errors }' name='companyFirstName')
      //-         b-form-input(placeholder="Prénom" v-model="documentEdit.companyCustomerFirstName")
      //-   div.w-50
      //-     b-form-group(label-for='companyLastName' style='flex:1')
      //-       validation-provider(#default='{ errors }' name='companyLastName')
      //-         b-form-input(placeholder="Nom" v-model="documentEdit.companyCustomerLastName")
      div.mb-1
        b-form-group(label-for='companyAddress' style='flex:1')
          validation-provider(#default='{ errors }' name='companyAddress')
            b-form-input(placeholder="Adresse" v-model="documentEdit.companyCustomerAddress") 
      div.mb-1
        b-form-group(label-for='companyAddressComplement' style='flex:1')
          validation-provider(#default='{ errors }' name='companyAddressComplement')
            b-form-input(placeholder="Adresse" v-model="documentEdit.companyCustomerAddressComplement") 
      div.mb-1.d-flex
        div.w-25
          b-form-group.pr-1(label-for='companyZipCode' style='flex:1')
            validation-provider(#default='{ errors }' name='companyZipCode')
              b-form-input(placeholder="CP"  v-model="documentEdit.companyCustomerZipCode")
        div.w-75
          b-form-group(label-for='companyCity' style='flex:1')
            validation-provider(#default='{ errors }' name='companyCity')
              b-form-input(placeholder="Ville" v-model="documentEdit.companyCustomerCity")
      div.mb-1
        b-form-group(label-for='companyCountry' style='flex:1')
          validation-provider(#default='{ errors }' name='companyCountry')
            b-form-input(placeholder="Pays" v-model="documentEdit.companyCustomerCountry")
      div.mb-1(v-if="document.isShowSiretCustomer")
        b-form-group(label-for='companySiret' style='flex:1')
          validation-provider(#default='{ errors }' name='companySiret')
            b-form-input(placeholder="Siret" v-model="documentEdit.companyCustomerSiret") 
    .card-info(v-else-if="document.companyCustomerId" @mouseenter="showCardTool = true" @mouseleave="showCardTool = false")
      div(v-if="!editMode")
        .card-tools.pr-0(v-if="showCardTool")
          feather-icon(icon="Edit3Icon" size="18" v-b-tooltip.hover.top="'Modifier le client'" @click="setEditMode()")
          feather-icon(icon="XIcon" size="18" @click="resetValue"  v-b-tooltip.hover.top="'Changer de client'")
        p.text-primary.font-weight-bold Client lié :
        div(v-if="document.companyCustomerName != null")
          span.font-weight-bold {{ document.companyCustomerName  }}
        //- div(v-if="document.companyCustomerFirstName != null || document.companyCustomerLastName != null")
        //-   span.bold {{ document.companyCustomerFirstName + ' ' + document.companyCustomerLastName }}
        div(v-if="document.companyCustomerAddress != null")
          span {{ document.companyCustomerAddress }}
        div(v-if="document.companyCustomerAddressComplement != null")
          span {{ document.companyCustomerAddressComplement }}
        div(v-if="document.companyCustomerZipCode != null || document.companyCustomerCity != null")
          span {{ document.companyCustomerZipCode }} {{ document.companyCustomerCity }}
        div(v-if="document.companyCustomerCountry != null")
          span {{ document.companyCustomerCountry }}
        div(v-if="document.isShowSiretCustomer && document.companyCustomerSiret != null")
          span Siret : {{ document.companyCustomerSiret }}
  .quote-card(v-else)
    .card-info
      p.text-primary.font-weight-bold Client lié :
      div(v-if="document.companyCustomerName != null")
        span.font-weight-bold {{ document.companyCustomerName  }}
      //- div(v-if="document.companyCustomerFirstName != null || document.companyCustomerLastName != null")
      //-   span.bold {{ document.companyCustomerFirstName + ' ' + document.companyCustomerLastName }}
      div(v-if="document.companyCustomerAddress != null")
        span {{ document.companyCustomerAddress }}
      div(v-if="document.companyCustomerAddressComplement != null")
        span {{ document.companyCustomerAddressComplement }}
      div(v-if="document.companyCustomerZipCode != null || document.companyCustomerCity != null")
        span {{ document.companyCustomerZipCode }} {{ document.companyCustomerCity }}
      div(v-if="document.companyCustomerCountry != null")
        span {{ document.companyCustomerCountry }}
      div(v-if="document.isShowSiretCustomer && document.companyCustomerSiret != null")
        span Siret : {{ document.companyCustomerSiret }}
</template>

<script>
import vSelect from "vue-select";
import { VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";

configure({
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");

export default {
  components: {
    "v-select": vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    preview: {
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: null,
      showCardTool: false,
      required,
      editMode: false,
      documentEdit: {},
    };
  },
  created() {
    this.getCompanies({});
  },
  computed: {
    ...mapGetters(["isLoadingCompaniesList"]),
    document: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions(["getCompanies"]),
    validModal(editCompany) {
      if (editCompany) {
        const updateData = {
          id: this.document.companyCustomerId,
          name: this.documentEdit.companyCustomerName,
          firstName: this.documentEdit.companyCustomerFirstName,
          lastName: this.documentEdit.companyCustomerLastName,
          address: this.documentEdit.companyCustomerAddress,
          addressComplement: this.documentEdit.companyCustomerAddressComplement,
          zipCode: this.documentEdit.companyCustomerZipCode,
          city: this.documentEdit.companyCustomerCity,
          country: this.documentEdit.companyCustomerCountry,
          phoneNumber: this.documentEdit.companyCustomerPhoneNumber,
          mail: this.documentEdit.companyCustomerMail,
          tva: this.documentEdit.companyCustomerTva,
          siret: this.documentEdit.companyCustomerSiret,
        };
        this.$store
          .dispatch("getCompanyById", {
            companyId: this.document.companyCustomerId,
          })
          .then((res) => {
            this.$store.dispatch("updateCompany", {
              company: {
                ...res,
                ...updateData,
                companyTypeId: res.companyType.id,
              },
            });
          });
      }
      this.document = this.documentEdit;
      this.editMode = false;
    },
    validEditMode() {
      this.validModal(false);
    },
    setEditMode() {
      this.editMode = true;
      // copy document into new variable
      this.documentEdit = JSON.parse(JSON.stringify(this.document));
    },
    setValue(value) {
      if (value.id !== 0 && value.id !== null) {
        this.$emit(
          "setValue",
          this.options.find((elem) => {
            return elem.id == value.value;
          })
        );
      } else {
        this.$emit("setValue", value);
      }
    },
    resetValue() {
      let value = {
        id: null,
        name: null,
        firstName: null,
        lastName: null,
        address: null,
        addressComplement: null,
        city: null,
        zipCode: null,
        country: null,
        email: null,
        phoneNumber: null,
        tva: null,
        siret: null,
      };
      this.value = value;
      this.setValue(value);
      this.document.companyCustomerId = null;
      this.document.companyCustomerName = null;
      this.document = {
        ...this.document,
        affairId: null,
        affairName: null,
        affairAddress: null,
        affairAddressComplement: null,
        affairZipCode: null,
        affairCity: null,
        affairCountry: null,
      };
    },
    newCustomer() {
      this.$router.push({ path: "/directory/new-company/clients/5" });
    },
  },
};
</script>

<style scoped>
#button-section {
  display: flex;
  justify-content: flex-end;
}
#button-section button {
  margin-left: 10px;
  width: 30em;
}
.card-info {
  padding: 10px;
  position: relative;
  background-color: #f1f1f1 !important;
  border: solid 2px #f1f1f1;
  border-radius: 3px;
}

.card-tools {
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: white;  */
  padding: 10px;
  border-bottom: solid 2px #f1f1f1;
}

.card-tools > * {
  margin-right: 10px;
  cursor: pointer;
}
</style>

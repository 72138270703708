<template lang="pug"> 
  
  div(v-if="orderFormProviderHeader[dataKey].inputType == 1") 
    TextCol(@emitEditFocus="emitEditFocus" :line="line" :canEdit="false" :dataKey="dataKey" :orderFormProviderHeader="orderFormProviderHeader" :edit="edit" :editFocus="editFocus")
  div(v-else-if="orderFormProviderHeader[dataKey].inputType == 25")  
    TitleCol(@emitEditFocus="emitEditFocus" :line="line" :dataKey="dataKey" :orderFormProviderHeader="orderFormProviderHeader" :canEdit="true" :edit="edit" :editFocus="editFocus")
</template>
<script>
import TitleCol from "@/components/purchase/order-form-provider/builder/table/cols/TitleCol.vue";
import TextCol from "@/components/purchase/order-form-provider/builder/table/cols/TextCol.vue";

export default {
  data() {
    return {};
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    orderFormProviderHeader: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    },
  },
  components: {
    TitleCol,
    TextCol,
  },
};
</script>

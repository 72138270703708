<template lang="pug">
  div(v-if="orderFormProviderHeader[dataKey].inputType == 1") 
    TextCol(@emitEditFocus="emitEditFocus" :line="line" :canEdit="false" :dataKey="dataKey" :orderFormProviderHeader="orderFormProviderHeader" :edit="edit" :editFocus="editFocus" :mouseOn="mouseOn")
  div(v-else-if="orderFormProviderHeader[dataKey].inputType == 10") 
    SelectCol(@emitEditFocus="emitEditFocus" :line="line" :canEdit="false" :dataKey="dataKey" :orderFormProviderHeader="orderFormProviderHeader" :edit="edit" :editFocus="editFocus" :mouseOn="mouseOn") 
  div(v-else-if="orderFormProviderHeader[dataKey].inputType == 24") 
    SelectCatalog(@emitEditFocus="emitEditFocus" :line="line" :canEdit="false" :dataKey="dataKey" :orderFormProviderHeader="orderFormProviderHeader" :edit="edit" :editFocus="editFocus" :mouseOn="mouseOn") 
</template>

<script>
import TextCol from "@/components/purchase/order-form-provider/builder/table/cols/TextCol.vue";
import SelectCatalog from "@/components/purchase/order-form-provider/builder/table/cols/SelectCatalog.vue";
import SelectCol from "@/components/purchase/order-form-provider/builder/table/cols/SelectCol.vue";

export default {
  data() {
    return {};
  },
  props: {
    mouseOn: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    orderFormProviderHeader: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    },
  },
  components: {
    TextCol,
    SelectCatalog,
    SelectCol,
  },
};
</script>

<style>
.input {
  padding: 0.375rem 0.75rem;
}
.no-edit {
  border: none;
}
.form-control:disabled {
  border: 1px solid #fff;
  background-color: white;
}
</style>

<template lang="pug">
  td
    div(v-if="orderFormProviderHeader[dataKey].inputType == 1") 
      span {{orderFormProviderHeader[dataKey].prefix }} 
      span {{orderFormProviderHeader[dataKey].numberToFixed ? formatNumber(line[dataKey]) : line[dataKey]}}
      span &nbsp;{{orderFormProviderHeader[dataKey].unit}}
    div(v-else-if="orderFormProviderHeader[dataKey].inputType == 2") 
      span {{formatNumberToString(line[dataKey])}}
      span &nbsp;{{orderFormProviderHeader[dataKey].unit}}
    div(v-else-if="orderFormProviderHeader[dataKey].inputType == 10") 
      SelectCol(@emitEditFocus="emitEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :orderFormProviderHeader="orderFormProviderHeader" :edit="edit" :editFocus="editFocus" :preview="true") 
    div(v-else-if="orderFormProviderHeader[dataKey].inputType == 24") 
      span(v-html="line[dataKey]")
</template>

<script>
import SelectCol from "@/components/purchase/order-form-provider/builder/table/cols/SelectCol.vue";
import TextCol from "@/components/purchase/order-form-provider/builder/table/cols/TextCol.vue";
import NumberCol from "@/components/purchase/order-form-provider/builder/table/cols/NumberCol.vue";
import SelectCatalog from "@/components/purchase/order-form-provider/builder/table/cols/SelectCatalog.vue";
import { formatNumber, formatNumberToString } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  data() {
    return {};
  },
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    orderFormProviderHeader: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    formatNumber,
    formatNumberToString,
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    },
  },
  components: {
    SelectCol,
    TextCol,
    NumberCol,
    SelectCatalog,
  },
};
</script>

<style>
.form-control:disabled {
  border: 1px solid #fff;
  background-color: white;
}
</style>

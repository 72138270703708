<template lang="pug">
.quote-card(v-if="!preview")
  div(v-if="!orderFormProvider.userId")
    b-form-group(label-for='storage-location')
      validation-provider(#default='{ errors }' name='storage-location')
        v-select#company.mb-1(ref="selectUser" @input="setUser" :loading="isLoadingCollaboratorsList" :state="errors.length > 0 ? false : null" v-model='orderFormProvider.userId' :options="collaboratorsList.filter((collab) => collab.userId)" :get-option-label="(collab) => collab.displayLabel" :reduce="(elem) => elem.userId" :placeholder="placeholder")
          template(v-slot:no-options)
            template  Aucun résultat trouvé
        small.text-danger {{ errors[0] }}
  div(v-if="editMode")
    div.pb-1.d-flex(:class="editMode ? 'justify-content-between' : ''")
      span.text-primary.font-weight-bold Collaborateur :
      span.cursor-pointer.text-primary(v-if="editMode" @click='saveUser') Enregistrer
    div.mb-1
      b-form-group(label-for='collabId' style='flex:1')
        validation-provider(#default='{ errors }' name='collabId' rules="required")
          v-select#collabId(:loading='isLoadingCollaboratorsList' :clearable='false' :deselectFromDropdown='true' :closeOnSelect='true' :state='errors.length > 0 ? false : null' v-model='orderFormProvider.userId' :options="collaboratorsList.filter((collab) => collab.userId)" :get-option-label="(collab) => collab.displayLabel" :reduce="(elem) => elem.userId" @input="setUser")
                template(v-slot:no-options='')
                    template  Aucun résultat trouvé
    //div.mb-1.d-flex
      div.w-50
        b-form-group(label-for='userFirstName' style='flex:1')
          validation-provider(#default='{ errors }' name='userFirstName')
          b-form-input(placeholder="Prénom" v-model="orderFormProvider.userFirstName")
      div.w-50
        b-form-group(label-for='userLastName' style='flex:1')
          validation-provider(#default='{ errors }' name='userLastName')
          b-form-input(placeholder="Nom" v-model="orderFormProvider.userLastName")
    div.mb-1
      b-form-group(label-for='collabMail' style='flex:1')
        validation-provider(#default='{ errors }' name='collabMail')
          b-form-input(placeholder="Email" v-model="orderFormProvider.userMail")
    div.mb-1.d-flex
      div.w-50
        b-form-group(label-for='userPhoneNumber' style='flex:1')
          validation-provider(#default='{ errors }' name='userPhoneNumber')
            b-form-input(placeholder="Téléphone principal" v-model="orderFormProvider.userPhoneNumber")
      div.w-50
        b-form-group(label-for='userSecondaryPhoneNumber' style='flex:1')
          validation-provider(#default='{ errors }' name='userSecondaryPhoneNumber')
            b-form-input(placeholder="Téléphone secondaire" v-model="orderFormProvider.userSecondaryPhoneNumber")
  .card-info(v-else-if="orderFormProvider.userId" @mouseenter="showCardTool = true" @mouseleave="showCardTool = false")
    div(v-if="!editMode")
      .card-tools.pr-0(v-if="showCardTool")
        feather-icon(icon="Edit3Icon" size="18" v-b-tooltip.hover.top="'Modifier le collaborateur'" @click="editMode=true")
        feather-icon(icon="XIcon" size="18" @click="resetUser"  v-b-tooltip.hover.top="'Changer de collaborateur'")
      p.mb-50.text-primary.font-weight-bold {{ orderFormProvider.userFirstName }} {{ orderFormProvider.userLastName }}
      div
        span {{ orderFormProvider.userMail ? "E-mail : " + orderFormProvider.userMail : "" }}
      div
        span {{ orderFormProvider.userPhoneNumber ? "Tél : " + orderFormProvider.userPhoneNumber : "" }}
      div
        span {{ orderFormProvider.userSecondaryPhoneNumber ? "Tél secondaire: " + orderFormProvider.userSecondaryPhoneNumber : "" }}
.quote-card(v-else)
  .card-info
    p.mb-50.text-primary.font-weight-bold {{ orderFormProvider.userFirstName }} {{ orderFormProvider.userLastName }}
    div
      span {{ orderFormProvider.userMail ? "E-mail : " + orderFormProvider.userMail : "" }}
    div
      span {{ orderFormProvider.userPhoneNumber ? "Tél : " + orderFormProvider.userPhoneNumber : "" }}
    div
      span {{ orderFormProvider.userSecondaryPhoneNumber ? "Tél secondaire: " + orderFormProvider.userSecondaryPhoneNumber : "" }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { BFormInput, BFormGroup, BButton } from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import vSelect from "vue-select";
import { mask } from "vue-the-mask";

export default {
  props: {
    preview: {
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editMode: false,
      showCardTool: false,
    };
  },
  created() {
    this.getCollaborators({});
  },
  computed: {
    ...mapGetters(["isLoadingCollaboratorsList", "collaboratorsList"]),
    orderFormProvider: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateOrderForm", "getCollaborators"]),
    setUser(value) {
      let collab = this.collaboratorsList.find((el) => el.userId == value);
      this.orderFormProvider.userLastName = collab.lastName;
      this.orderFormProvider.userFirstName = collab.firstName;
      this.orderFormProvider.userPhoneNumber = collab.phoneNumber;
      this.orderFormProvider.userMail = collab.email;
    },
    saveUser() {
      this.editMode = false;
      this.updateOrderForm({
        orderFormProvider: this.orderFormProvider,
        loading: false,
      });
    },
    resetUser() {
      this.orderFormProvider.userLastName = "";
      this.orderFormProvider.userFirstName = "";
      this.orderFormProvider.userPhoneNumber = "";
      this.orderFormProvider.userMail = "";
      this.orderFormProvider.userId = null;
      this.saveUser();
    },
  },
  directives: {
    mask,
  },
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
};
</script>

<template lang="pug">
.dropdown.search-select(v-if="canEdit && !preview")
  quill-editor(ref="quillEditor" @focus="focusFunction(true)" @blur="blurFunction(false)"  :disabled="line.isDisabled"  :class="[(editFocus && mouseOn) || (searchCatalog && mouseOn) ? 'edit' : 'no-edit', editFocus? 'focus' : '', searchCatalog && isFocusSearchCatalog ? 'searchCatalog' : '', line.isDisabled && dataKey!=='situationProgressQuantity' && dataKey!=='situationProgress' ? 'disabled' :'']" v-model="line[dataKey]" :options="editorOption")
  span.feather-search-catalog(class='material-icons' :class="editFocus && mouseOn  ? 'edit' : ''" @click="activeSearchCatalog()") search
  input(class="w-100" :ref="'fakeInputSelectCatalogOrder'+index" style="width: 0px !important; position: absolute; display:block")
  .select-results.dropdown-content(v-show="searchCatalog")
    input(class="w-100 edit" @focus="focusSearchCatalog(true)"  @blur="blurSearchCatalog(false)" name="keywordsCatalog" ref="keywordsCatalog" placeholder="Saisissez vos mots-clés ou une référence pour rechercher dans le catalogue" v-model="keywordsCatalog") 
    div(v-if="keywordsCatalog.length > 1")
      div(v-for="(item, key) in productsListByTypeLine(line, productProviderSortedByProductType, keywordsCatalog, orderFormProvider.companyProviderId)")
        span.select-title {{ key }}
        div(v-if="item.length > 0")
          .d-flex.align-items-end(v-for="(product, index) in item")
            .d-flex.p-50.cursor-pointer.select-catalogue-line(style="flex:1" @click="affectValue(product)")
              div(style="width:100px") {{ product.reference }}
              div(style="flex:1")
                span(v-html="product.description")        
              div.text-right(style="width:100px")
                span {{ product.netPrice.toFixed(2) }} €      
        div.text-center(v-else)
          span
            | Aucun produit trouvé de type {{ key.toLowerCase() }}
div(v-else)
  span(v-html="line[dataKey]")
</template>

<script>
import vSelect from "vue-select";
import { EProductType } from "@/types/api-orisis/enums/enums.ts";
import { mapGetters, mapActions } from "vuex";
import { EProductLabelType } from "@/types/api-orisis/enums/enums";
import { productsListByTypeLine } from "@/types/api-orisis/library/DetailOrderFormProviderOperations.ts";
import { calculateSubTotalLineBySection } from "@/types/api-orisis/library/OrderFormProviderOperations";
import { calculateTotalOrderFormProviderLine } from "@/types/api-orisis/library/DetailOrderFormProviderOperations";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      isFocus: false,
      isFocusSearchCatalog: false,
      lineOrigine: {},
      editorOption: {
        placeholder: "Description...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
      searchCatalog: false,
      keywordsCatalog: "",
      calculateSubTotalLineBySection,
      productsListByTypeLine,
    };
  },
  props: {
    preview: {
      default: false,
    },
    mouseOn: {
      type: Boolean,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    orderFormProviderHeader: {
      type: Object,
      required: false,
    },
    index: {
      type: Number,
      required: false,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
    },
  },
  watch: {
    editFocus(val) {
      if (
        val == false &&
        (this.editFocus == true || this.searchCatalog == true)
      ) {
        this.$refs["fakeInputSelectCatalogOrder" + this.index]?.focus({
          preventScroll: true,
        });
        this.searchCatalog = false;
        this.keywordsCatalog = "";
      } else if (val == false) {
        this.searchCatalog = false;
        this.keywordsCatalog = "";
      }
    },
  },
  created() {
    this.lineOrigine = JSON.parse(JSON.stringify(this.line[this.dataKey]));
    this.searchCatalog = false;
  },
  mounted() {
    this.searchCatalog = false;
  },
  computed: {
    ...mapGetters(["productProviderSortedByProductType", "orderFormProvider"]),
    documentDetails: {
      get() {
        return this.$store.getters["getOrderFormProviderDetails"];
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_DETAILS", value);
      },
    },
    document: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "updateOrderFormProviderDetail",
      "updateOrderForm",
      "getCollaboratorById",
      "getCompanyById",
    ]),
    getDefaultAddressCompany(company) {
      return new Promise((resolve, reject) => {
        company.addresses.find((adress) => {
          if (adress.isDefault) {
            resolve(adress);
          }
        });
      });
    },
    saveAction() {
      this.updateOrderForm({
        orderFormProvider: this.document,
        loading: false,
      });
    },
    activeSearchCatalog() {
      this.searchCatalog = true;
      let _this = this;
      this.$nextTick(() => {
        _this.$refs.keywordsCatalog.focus({ preventScroll: true });
      });
    },
    focusSearchCatalog(res) {
      this.isFocusSearchCatalog = res;
    },
    blurSearchCatalog(res) {
      if (!this.mouseOn) {
        this.$emit("emitEditFocus", false);
        this.searchCatalog = false;
      }
    },
    focusFunction(res) {
      this.$emit("emitEditFocus", res);
    },
    blurFunction(res) {
      this.$emit("emitEditFocus", res);
      // if(!this.mouseOn) this.$emit("emitEditFocus", res);
      if (
        JSON.parse(JSON.stringify(this.line[this.dataKey])) !== this.lineOrigine
      ) {
        this.updateOrderFormProviderDetail({
          orderFormProviderDetail: this.line,
        });
      }
    },
    async setSelectProvider(company) {
      if (company.id == null) {
        this.collaboratorInfo = null;
        this.document = {
          ...this.document,
          companyProviderId: company.id,
          companyProviderName: company.name,
          companyProviderAddress: company.address,
          companyProviderAddressComplement: company.addressComplement,
          companyProviderCity: company.city,
          companyProviderZipCode: company.zipCode,
          companyProviderCountry: company.country,
          companyProviderSiret: company.siret,
        };
        // this.saveAction();
      } else {
        await this.getCompanyById({ companyId: company.id }).then(
          async (company) => {
            this.collaboratorInfo = company.collaboratorId
              ? await this.getCollaboratorById({
                  collaboratorId: company.collaboratorId,
                })
              : null;
            if (company.addresses && company.addresses.length > 0) {
              await this.getDefaultAddressCompany(company).then((res) => {
                this.document = {
                  ...this.document,
                  companyProviderId: company.id,
                  companyProviderName: company.name,
                  companyProviderAddress: res.address,
                  companyProviderAddressComplement: res.addressComplement,
                  companyProviderCity: res.city,
                  companyProviderZipCode: res.zipCode,
                  companyProviderCountry: res.country,
                  companyProviderSiret: res.siret,
                };
              });
            } else {
              this.document = {
                ...this.document,
                companyProviderId: company.id,
                companyProviderName: company.name,
                companyProviderSiret: company.siret,
                companyProviderAddress: null,
                companyProviderAddressComplement: null,
                companyProviderCity: null,
                companyProviderZipCode: null,
                companyProviderCountry: null,
              };
            }
          }
        );
        this.saveAction();
      }
    },
    async affectValue(val) {
      if (
        this.documentDetails.includes(
          (elem) => elem.productProviderId == val.id
        )
      ) {
        this.$bvToast.toast(
          "Ce produit est déjà présent dans le bon de réception",
          {
            title: "Erreur",
            variant: "danger",
            solid: true,
          }
        );
      } else {
        //Si c'est le premier produit des détails, on affecte le fournisseur du produit au bon de commande
        if (
          !this.documentDetails.find((elem) => elem.productProviderId != null)
        ) {
          this.setSelectProvider(val.company);
        }
        this.line["description"] = val.description
          ? val.description
          : val.label;
        this.line["quantity"] = val.quantity ? val.quantity : 0;
        this.line["productProviderId"] = val.id;
        this.line["reference"] = val.reference ? val.reference : null;
        this.line["unitId"] = val.unit ? val.unit.id : this.line["unitId"];
        this.line["unitPriceHt"] = val.netPrice
          ? val.netPrice
          : this.line["unitPriceHt"];
        this.line["total"] = val.netPrice
          ? Math.round(this.line["quantity"] * val.netPrice * 100) / 100
          : this.line["total"];
        this.line["productId"] = val.productId ? val.productId : null;
        this.line["referencielTvaId"] = val.referencielTvaId
          ? val.referencielTvaId
          : this.line["referencielTvaId"];
        this.line["discount"] = val.discount ? val.discount : 0;
        this.line["label"] = val.label ? val.label : null;
        this.line["marginRate"] = val.marginRate ? val.marginRate : 0;
        this.line["ecoContribution"] = val.ecoContribution
          ? val.ecoContribution
          : 0;
        this.line["grossMarginHT"] = val.grossMarginHT ? val.grossMarginHT : 0;
        this.line["isDefault"] = val.isDefault;
        this.line["useDegressivePurchaseRate"] = val.useDegressivePurchaseRate
          ? val.useDegressivePurchaseRate
          : 0;
        this.line["purchaseCategoryId"] = val.purchaseCategoryId
          ? val.purchaseCategoryId
          : null;
        this.line["productType"] = val.productType ? val.productType : 0;
        this.line["netPrice"] = val.netPrice ? val.netPrice : 0;
        this.line["amountHt"] = val.amountHt ? val.amountHt : 0;

        switch (val.productType) {
          case 0:
            this.line["type"] = 7;
            break;
          case 2:
            this.line["type"] = 9;
            break;
          case 4:
            this.line["type"] = 6;
            break;
          case 5:
            this.line["type"] = 8;
            break;
        }
        await this.updateOrderFormProviderDetail({
          orderFormProviderDetail: this.line,
        });
        await this.calculateSubTotalLineBySection(this.line);
        this.keywordsCatalog = "";
        this.searchCatalog = false;
      }
    },
  },

  components: {
    "v-select": vSelect,
    quillEditor,
  },
};
</script>

<template lang="pug">
div(v-if="!orderFormProviderHeader[dataKey].editable")
  div.content-span-document-detail
    span(v-model="line[dataKey]") 
    span {{ orderFormProviderHeader[dataKey].prefix }} 
    span {{ formatNumberToString(line[dataKey]) }}
    span {{ orderFormProviderHeader[dataKey].unit }}
input.w-100(v-else @focus="emitEditFocus(true)" @blur="blurFunction(false)" type="number" min='0'  :class="edit && canEdit ? 'edit' : 'no-edit'" v-model="line[dataKey]") 
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { calculateSubTotalLineBySection } from "@/types/api-orisis/library/OrderFormProviderOperations";
import {
  formatNumber,
  formatNumberToString,
} from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  data() {
    return {
      calculateSubTotalLineBySection,
    };
  },
  methods: {
    ...mapActions(["updateOrderFormProviderDetail"]),
    formatNumber,
    formatNumberToString,
    change(res, calculateSubTotal = false) {
      this.line[this.dataKey] = formatNumber(this.line[this.dataKey]);
      if (res && res.target.value > 100 && this.dataKey == "discount") {
        this.line[this.dataKey] = 100;
        this.change({ ...res, target: { value: 100 } }, true);
      } else {
        let discount =
          Math.round(((100 - this.line["discount"]) / 100) * 100) / 100;
        this.line["total"] = formatNumber(
          this.line["unitPriceHt"] * this.line["quantity"] * discount
        );
      }
      if (calculateSubTotal) this.calculateSubTotalLineBySection(this.line);
    },
    async blurFunction(res) {
      this.$emit("emitEditFocus", res);
      await this.change(null, true);
      await this.updateOrderFormProviderDetail({
        orderFormProviderDetail: this.line,
      });
    },
    emitEditFocus(res) {
      this.edit = res;
      this.$emit("emitEditFocus", res);
    },
  },
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    orderFormProviderHeader: {
      type: Object,
      required: false,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
    mouseOn: {
      type: Boolean,
    },
  },
};
</script>

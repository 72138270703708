<template lang="pug">
div 
  b-row
    b-col(cols="12").col-lg-5
      ul
        li 
          h3.text-primary Commande n° {{ orderFormProvider.documentReference ? orderFormProvider.documentReference : 'en attente' }} 
            span(style='font-weight:300') 
        li.d-flex.align-items-center
          Status(:statusId="orderFormProvider.status" nature="orderFormProvider" :static="true")
          SelectDate(keyValue="documentDate" @setValue="setDocumentDate" documentDetailKey="documentDate" text="En date du ")
        div(v-if="!orderFormOption.hideUser")
          vs-divider.pl-0.mt-2.mb-50.text-primary(position="left") Traité par
          li
            UserInfoOrderFormProvider(placeholder="Sélectionner un utilisateur" )
        div
          vs-divider.pl-0.mt-1.mb-50.text-primary(position="left") Associé à un client / une affaire
          li
            SelectCompany(@setValue="setSelectCustomer" placeholder="Sélectionner un client" :options='companiesList.filter(elem => elem.companyType.id == 5)')
          li
            SelectAffair(@setValue="setSelectAffair" placeholder="Sélectionner une affaire" :options='affairsList')
    b-col(cols="12").offset-lg-1.col-lg-6
      div
        vs-divider.pl-0.mt-0.mb-50.text-primary(position="left") Fournisseur
        div
          SelectProvider.mb-1(@setValue="setSelectProvider" placeholder="Sélectionner un fournisseur" :options='companiesList.filter(elem => elem.companyType.id == 4 || elem.companyType.id == 8)')
      div
        vs-divider.pl-0.mt-0.mb-50.text-primary(position="left") Adresse de Livraison 
        div
          SelectStorageLocation(@setValue="setSelectStorageLocation" @setValueDeliverToAffairAddress="setValueDeliverToAffairAddress" placeholder="Sélectionner un point de stockage" :options='storageLocationsList')
      div
        SelectDate(keyValue="deliveryDate" @setValue="setDeliveryDate" documentDetailKey="deliveryDate" text="Livraison souhaitée le " style="margin-bottom:5px")
      div(v-if="orderFormProvider.affairId")
        SelectDate(keyValue="interventionDate" @setValue="setInterventionDate" documentDetailKey="interventionDate" text="Date d'intervention le " style="margin-bottom:5px")
  b-row
    b-col.mb-0(cols="12" class="align-items-center")
      OrderFormProviderDescription(@setValue="setDescription" :key="'Description_'+orderFormProvider.id")
</template>
<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import Status from "@/components/global/status/Status";

import SelectCompany from "@/components/purchase/order-form-provider/builder/SelectCompany";

import SelectCustomer from "@/components/purchase/order-form-provider/builder/SelectCustomer";
import SelectProvider from "@/components/purchase/order-form-provider/builder/SelectProvider";
import SelectStorageLocation from "@/components/purchase/order-form-provider/builder/SelectStorageLocation";
import SelectAffair from "@/components/purchase/order-form-provider/builder/SelectAffair";
import SelectDate from "@/components/purchase/order-form-provider/builder/SelectDate";
import SelectDateCEE from "@/components/invoice/builder/SelectDateCEE";
import SelectDateWork from "@/components/invoice/builder/SelectDateWork";
import SelectPeriod from "@/components/invoice/builder/SelectPeriod";
import OrderFormProviderDescription from "@/components/purchase/order-form-provider/builder/OrderFormProviderDescription";
import SelectCounterDate from "@/components/invoice/builder/SelectCounterDate";
import UserInfoOrderFormProvider from "@/components/purchase/order-form-provider/builder/UserInfoOrderFormProvider";
import {
  natureTranslate,
  natureSimplifiedTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  data() {
    return {
      collaboratorInfo: null,
    };
  },
  mounted() {
    this.setCollaboratorInfo();
  },
  methods: {
    ...mapActions(["updateOrderForm", "getCompanyById", "getCollaboratorById"]),
    natureTranslate,
    natureSimplifiedTranslate,
    setDocumentDate(date) {
      let paymentCondition = this.paymentConditionsList.find(
        (elem) => elem.id == this.orderFormProvider.paymentConditionId
      );
      this.orderFormProvider = {
        ...this.orderFormProvider,
        documentDate: date,
        limitDate: paymentCondition.days
          ? new Date(dayjs(date).add(paymentCondition.days, "day"))
          : this.orderFormProvider.limitDate,
      };
      this.saveAction();
    },
    setDeliveryDate(date) {
      this.orderFormProvider = {
        ...this.orderFormProvider,
        deliveryDate: date,
      };
      this.saveAction();
    },
    setInterventionDate(date) {
      this.orderFormProvider = {
        ...this.orderFormProvider,
        interventionDate: date,
      };
      this.saveAction();
    },
    getDefaultAddressCompany(company) {
      return new Promise((resolve, reject) => {
        company.addresses.find((adress) => {
          if (adress.isDefault) {
            resolve(adress);
          }
        });
      });
    },
    async setCollaboratorInfo() {
      if (this.orderFormProvider.companyId) {
        await this.getCompanyById({
          companyId: this.orderFormProvider.companyId,
        }).then(async (company) => {
          this.collaboratorInfo = company.collaboratorId
            ? await this.getCollaboratorById({
                collaboratorId: company.collaboratorId,
              })
            : null;
        });
      } else {
        this.collaboratorInfo = null;
      }
    },
    async setSelectProvider(company) {
      if (company.id == null) {
        this.collaboratorInfo = null;
        this.orderFormProvider = {
          ...this.orderFormProvider,
          companyProviderId: company.id,
          companyProviderName: company.name,
          companyProviderAddress: company.address,
          companyProviderAddressComplement: company.addressComplement,
          companyProviderCity: company.city,
          companyProviderZipCode: company.zipCode,
          companyProviderCountry: company.country,
          companyProviderSiret: company.siret,
          companyProviderMail: company.email,
          companyProviderPhoneNumber: company.phoneNumber,
          companyProviderCode: company.code,
          companyProviderTva: company.tvaNumber,
          companyProviderAddressLabel: company.addressLabel,
        };
        // this.saveAction();
      } else {
        await this.getCompanyById({ companyId: company.id }).then(
          async (company) => {
            this.collaboratorInfo = company.collaboratorId
              ? await this.getCollaboratorById({
                  collaboratorId: company.collaboratorId,
                })
              : null;
            if (company.addresses && company.addresses.length > 0) {
              await this.getDefaultAddressCompany(company).then((res) => {
                this.orderFormProvider = {
                  ...this.orderFormProvider,
                  companyProviderId: company.id,
                  companyProviderName: company.name,
                  companyProviderAddress: res.address,
                  companyProviderAddressComplement: res.addressComplement,
                  companyProviderCity: res.city,
                  companyProviderZipCode: res.zipCode,
                  companyProviderCountry: res.country,
                  companyProviderSiret: res.siret,
                  companyProviderMail: company.email,
                  companyProviderPhoneNumber: company.phoneNumber,
                  companyProviderCode: company.code,
                  companyProviderTva: company.tvaNumber,
                  companyProviderAddressLabel: res.label,
                };
              });
            } else {
              this.orderFormProvider = {
                ...this.orderFormProvider,
                companyProviderId: company.id,
                companyProviderName: company.name,
                companyProviderSiret: company.siret,
                companyProviderAddress: null,
                companyProviderAddressComplement: null,
                companyProviderCity: null,
                companyProviderZipCode: null,
                companyProviderCountry: null,
                companyProviderMail: company.email,
                companyProviderPhoneNumber: company.phoneNumber,
                companyProviderCode: company.code,
                companyProviderTva: null,
                companyProviderAddressLabel: null,
              };
            }
          }
        );
        this.saveAction();
      }
    },
    async setSelectCustomer(company) {
      if (company.id == null) {
        this.collaboratorInfo = null;
        this.orderFormProvider = {
          ...this.orderFormProvider,
          companyCustomerId: company.id,
          companyCustomerName: company.name,
          companyCustomerAddress: company.address,
          companyCustomerAddressComplement: company.addressComplement,
          companyCustomerCity: company.city,
          companyCustomerZipCode: company.zipCode,
          companyCustomerCountry: company.country,
          companyCustomerCode: company.code,
          companyCustomerPhoneNumber: company.phoneNumber,
          companyCustomerMail: company.email,
          companyCustomerSiret: company.siret,
          companyCustomerTva: company.tvaNumber,
          companyCustomerAddressLabel: company.addressLabel,
        };
        this.saveAction();
      } else {
        await this.getCompanyById({ companyId: company.id }).then(
          async (company) => {
            this.collaboratorInfo = company.collaboratorId
              ? await this.getCollaboratorById({
                  collaboratorId: company.collaboratorId,
                })
              : null;
            if (company.addresses && company.addresses.length > 0) {
              await this.getDefaultAddressCompany(company).then((res) => {
                this.orderFormProvider = {
                  ...this.orderFormProvider,
                  companyCustomerId: company.id,
                  companyCustomerName: company.name,
                  companyCustomerAddress: res.address,
                  companyCustomerAddressComplement: res.addressComplement,
                  companyCustomerCity: res.city,
                  companyCustomerZipCode: res.zipCode,
                  companyCustomerCountry: res.country,
                  companyCustomerCode: company.code,
                  companyCustomerPhoneNumber: company.phoneNumber,
                  companyCustomerMail: company.email,
                  companyCustomerSiret: res.siret,
                  companyCustomerTva: company.tvaNumber,
                  companyCustomerAddressLabel: res.label,
                };
              });
            } else {
              this.orderFormProvider = {
                ...this.orderFormProvider,
                companyCustomerId: company.id,
                companyCustomerName: company.name,
                companyCustomerAddress: null,
                companyCustomerAddressComplement: null,
                companyCustomerCity: null,
                companyCustomerZipCode: null,
                companyCustomerCountry: null,
                companyCustomerCode: company.code,
                companyCustomerPhoneNumber: company.phoneNumber,
                companyCustomerMail: company.email,
                companyCustomerSiret: null,
                companyCustomerTva: company.tvaNumber,
                companyCustomerAddressLabel: null,
              };
            }
          }
        );
        this.saveAction();
      }
    },
    setValueDeliverToAffairAddress(value) {
      if (value && !this.orderFormProvider.affairAddress) {
        this.orderFormProvider.affairAddress =
          this.orderFormProvider.storageLocationAddress =
            this.orderFormProvider.companyCustomerAddress;
        this.orderFormProvider.affairAddressComplement =
          this.orderFormProvider.storageLocationAddressComplement =
            this.orderFormProvider.companyCustomerAddressComplement;
        this.orderFormProvider.affairZipCode =
          this.orderFormProvider.storageLocationZipCode =
            this.orderFormProvider.companyCustomerZipCode;
        this.orderFormProvider.affairCity =
          this.orderFormProvider.storageLocationCity =
            this.orderFormProvider.companyCustomerCity;
        this.orderFormProvider.affairCountry =
          this.orderFormProvider.storageLocationCountry =
            this.orderFormProvider.companyCustomerCountry;
      } else if (value && this.orderFormProvider.affairAddress) {
        this.orderFormProvider.storageLocationAddress =
          this.orderFormProvider.affairAddress;
        this.orderFormProvider.storageLocationAddressComplement =
          this.orderFormProvider.affairAddressComplement;
        this.orderFormProvider.storageLocationZipCode =
          this.orderFormProvider.affairZipCode;
        this.orderFormProvider.storageLocationCity =
          this.orderFormProvider.affairCity;
        this.orderFormProvider.storageLocationCountry =
          this.orderFormProvider.affairCountry;
      } else {
        this.orderFormProvider.storageLocationAddress = null;
        this.orderFormProvider.storageLocationAddressComplement = null;
        this.orderFormProvider.storageLocationZipCode = null;
        this.orderFormProvider.storageLocationCity = null;
        this.orderFormProvider.storageLocationCountry = null;
      }
      this.saveAction();
    },
    setSelectStorageLocation(storageLocation) {
      this.orderFormProvider = {
        ...this.orderFormProvider,
        storageLocationId: storageLocation.id,
        storageLocationLabel: storageLocation.label,
        storageLocationAddress: storageLocation.address,
        storageLocationAddressComplement: storageLocation.addressComplement,
        storageLocationZipCode: storageLocation.zipCode,
        storageLocationCity: storageLocation.city,
        storageLocationEmail: storageLocation.email,
        storageLocationPhoneNumber: storageLocation.phoneNumber,
        storageLocationType: storageLocation.storageLocationType,
      };
      if (storageLocation.id) {
        this.saveAction();
      }
    },
    setSelectAffair(affair) {
      if (affair) {
        this.orderFormProvider.interventionDate = dayjs().format("YYYY-MM-DD");
      } else {
        this.orderFormProvider.interventionDate = null;
      }
      if (this.orderFormProvider.deliverToAffairAddress && !affair.address) {
        this.orderFormProvider.affairId = affair.id;
        this.orderFormProvider.affairName = affair.name;
        this.orderFormProvider.affairAddress =
          this.orderFormProvider.companyCustomerAddress;
        this.orderFormProvider.affairAddressComplement =
          this.orderFormProvider.companyCustomerAddressComplement;
        this.orderFormProvider.affairZipCode =
          this.orderFormProvider.companyCustomerZipCode;
        this.orderFormProvider.affairCity =
          this.orderFormProvider.companyCustomerCity;
        this.orderFormProvider.affairCountry =
          this.orderFormProvider.companyCustomerCountry;
        this.orderFormProvider.affairCode = affair.code;
      } else {
        this.orderFormProvider = {
          ...this.orderFormProvider,
          affairId: affair.id,
          affairName: affair.name,
          affairAddress: affair.address,
          affairAddressComplement: affair.addressComplement,
          affairZipCode: affair.zipCode,
          affairCity: affair.city,
          affairCountry: affair.country,
          affairCode: affair.code,
        };
      }
      this.saveAction();
    },
    setDescription(description) {
      this.orderFormProvider = {
        ...this.orderFormProvider,
        description: description,
      };
      this.saveAction();
    },
    saveAction() {
      this.updateOrderForm({
        orderFormProvider: this.orderFormProvider,
      });
    },
  },
  computed: {
    ...mapGetters([
      "companiesList",
      "affairsList",
      "storageLocationsList",
      "natureList",
      "paymentConditionsList",
      "orderFormOption",
    ]),
    orderFormProvider: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
  },
  components: {
    "v-select": vSelect,
    SelectCustomer,
    SelectProvider,
    SelectAffair,
    SelectStorageLocation,
    SelectDate,
    SelectPeriod,
    OrderFormProviderDescription,
    SelectCounterDate,
    SelectDateWork,
    UserInfoOrderFormProvider,
    SelectDateCEE,
    Status,
    SelectCompany,
  },
};
</script>
<style scoped>
.align-items-center {
  align-items: center;
}

.quote-card {
  margin-bottom: 10px;
}

ul li {
  list-style: none;
}

.bold {
  font-weight: bold;
}

a {
  color: #0c3571 !important;
  text-decoration: underline !important;
}
</style>

<template lang="pug">
div(class="invoiceHeader")
      b-row
        b-col.col-sm-6(class="invoiceHeaderLeft")
          img.logo.mb-2(v-if="orderFormProvider.institutionLogo && !orderFormOption.hideInstitutionLogo" :src='orderFormProvider.institutionLogo')
          ul 
            li.mb-2 
              h2(:style="'color:'+orderFormProvider.institutionColor+' !important'" v-if="!orderFormOption.hideInstitutionName") {{orderFormProvider.institutionName}}
              div(v-if="!orderFormOption.hideInstitutionAddress")
                div {{orderFormProvider.institutionAddress}}
                div(v-if="orderFormProvider.institutionAddressComplement") {{orderFormProvider.institutionAddressComplement}} 
                div(v-if="orderFormProvider.institutionZipCode || orderFormProvider.institutionCity") {{orderFormProvider.institutionZipCode}} {{orderFormProvider.institutionCity}}
            div(v-if="!orderFormOption.hideUser")
              vs-divider.pl-0.mt-2.mb-50.text-primary(position="left") Traité par
              li
                UserInfoOrderFormProvider(placeholder="Sélectionner un utilisateur" )
            div
              vs-divider.pl-0.mt-1.mb-50.text-primary(position="left" style="font-size: 90%;" v-if="orderFormProvider.companyCustomerId && (!orderFormOption.hideCompanyCustomer || !orderFormOption.hideAffair)") Associé à un client / une affaire : 
              li(v-if="orderFormProvider.companyCustomerId && !orderFormOption.hideCompanyCustomer")
                SelectCompany(:preview="true" placeholder="Sélectionner un client" :options='companiesList.filter(elem => elem.companyType.id == 5)')
              li(v-if="orderFormProvider.affairId && !orderFormOption.hideAffair")
                SelectAffair(:preview="true" placeholder="Sélectionner une affaire" :options='affairsList')
        b-col.col-sm-6(class="invoiceHeaderRight")
          ul(style="height:fit-content")
            li 
              h3(:style="'color:'+orderFormProvider.institutionColor+' !important'") Bon de commande n° {{ orderFormProvider.documentReference ? orderFormProvider.documentReference : 'en attente' }} 
                span(style='font-weight:300')
            li 
              SelectDate(keyValue="documentDate" documentDetailKey="documentDate" :preview="true" text="En date du ")
            li
              SelectCounterDate(keyValue="limitDate" :preview="true" text="Valable jusqu'au ")
            li
              br(style="height:10px")
            vs-divider.pl-0.mt-0.mb-50.text-primary(v-if="!orderFormOption.hideCompanyProvider" position="left") Fournisseur :
            li(v-if="!orderFormOption.hideCompanyProvider")
              SelectProvider(:options='companiesList.filter(elem => elem.companyType.id == 4)' :preview="true")
            div(v-if="!orderFormOption.hideStorageLocation")
              vs-divider.pl-0.mt-0.mb-50.text-primary(position="left" v-if="orderFormProvider.storageLocationAddress || orderFormProvider.storageLocationAddressComplement || orderFormProvider.storageLocationZipCode || orderFormProvider.storageLocationCity") Adresse de livraison :
              div(v-if="orderFormProvider.storageLocationAddress || orderFormProvider.storageLocationAddressComplement || orderFormProvider.storageLocationZipCode || orderFormProvider.storageLocationCity")
                SelectStorageLocation(:options='storageLocationsList' :preview="true")
            div
              SelectDate(keyValue="deliveryDate" documentDetailKey="deliveryDate" text="Livraison souhaitée le " style="margin-bottom:5px")
            div(v-if="orderFormProvider.affairId && orderFormProvider.interventionDate")
              SelectDate(keyValue="interventionDate" documentDetailKey="interventionDate" text="Date d'intervention le " style="margin-bottom:5px")
      b-row
        b-col.mb-0(cols="12" class="align-items-center orderFormProviderDescription" style="margin-top: 10px;")
          OrderFormProviderDescription(:key="'OrderFormProviderDescription'+orderFormProvider.id" :preview="true")
      v-style
        |table:after { background-color: {{orderFormProvider.institutionColor}} !important;}
</template>
<script>
import vSelect from "vue-select";
import { mapGetters } from "vuex";

import SelectCompany from "@/components/purchase/order-form-provider/builder/SelectCompany";
import SelectProvider from "@/components/purchase/order-form-provider/builder/SelectProvider";
import SelectStorageLocation from "@/components/purchase/order-form-provider/builder/SelectStorageLocation";
import SelectCustomer from "@/components/purchase/order-form-provider/builder/SelectCustomer";
import SelectAffair from "@/components/purchase/order-form-provider/builder/SelectAffair";
import SelectDate from "@/components/purchase/order-form-provider/builder/SelectDate";
import SelectDateWork from "@/components/purchase/order-form-provider/builder/SelectDateWork";
import SelectPeriod from "@/components/purchase/order-form-provider/builder/SelectPeriod";
import OrderFormProviderDescription from "@/components/purchase/order-form-provider/builder/OrderFormProviderDescription";
import SelectCounterDate from "@/components/purchase/order-form-provider/builder/SelectCounterDate";
import UserInfoOrderFormProvider from "@/components/purchase/order-form-provider/builder/UserInfoOrderFormProvider";
export default {
  data() {
    return {
      options: [
        { label: "Heure", id: 0 },
        { label: "Jour", id: 1 },
        { label: "Semaine", id: 2 },
        { label: "Mois", id: 3 },
        { label: "Année", id: 4 },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "institution",
      "companiesList",
      "affairsList",
      "storageLocationsList",
      "orderFormOption",
    ]),
    orderFormProvider: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
  },
  components: {
    "v-select": vSelect,
    SelectCompany,
    SelectCustomer,
    SelectAffair,
    SelectDate,
    SelectPeriod,
    OrderFormProviderDescription,
    SelectCounterDate,
    SelectDateWork,
    UserInfoOrderFormProvider,
    SelectProvider,
    SelectStorageLocation,
  },
};
</script>
<style scoped>
.align-items-center {
  align-items: center;
}

.quote-card {
  margin-bottom: 10px;
}

ul li {
  list-style: none;
}

.bold {
  font-weight: bold;
}

a {
  color: #0c3571 !important;
  text-decoration: underline !important;
}
</style>

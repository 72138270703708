<template lang="pug">
  div
      div
          div.d-flex(v-if="!preview || (preview && document.paymentMethod)")
              h4 Conditions de paiement
          SelectValidityDate(keyValue="limitDate" @setValue="saveAction" text="Délai de paiement : " :preview="preview")
          PaymentMethodChoice(:preview="preview")
      BottomNotes(:preview="preview")

</template>
<script>
import { mapGetters, mapActions } from "vuex";
import PaymentMethodChoice from "@/components/purchase/order-form-provider/builder/PaymentMethodChoice";
import BottomNotes from "@/components/purchase/order-form-provider/builder/BottomNotes.vue";
import SelectValidityDate from "@/components/purchase/order-form-provider/builder/SelectValidityDate";

export default {
  components: {
    PaymentMethodChoice,
    BottomNotes,
    SelectValidityDate,
  },
  props: {
    preview: {
      default: false,
    },
  },
  computed: {
    document: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateOrderForm"]),
    saveAction() {
      this.updateOrderForm({
        orderFormProvider: this.document,
        loading: false,
      });
    },
  },
};
</script>

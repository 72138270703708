<template lang="pug">
.builder.pb-4
  b-alert.mt-2(v-if="mandatoryInformationMissingNumber" variant='warning' show='')
    p.alert-heading
      | Certaines mentions légales sont manquantes. <a  class="cursor-pointer" style="font-weight: bold" @click="redirectToInstitution">Cliquez ici pour les ajouter</a>
  b-alert.mt-2(v-if="institutionInformationsNotUpToDate && document.status < 3" variant='warning' show='')
    p.alert-heading
      | Les informations de votre document ne sont pas à jour. <a class="cursor-pointer" style="font-weight: bold"  @click="updateDocumentInstitutionInformations">Cliquez ici pour les mettres à jour</a>
  .builder-area
    OrderFormProviderHeader()
    OrderFormProviderTable()
    OrderFormProviderBTools()
    OrderFormProviderFooter()
  </template>

<script>
import OrderFormProviderHeader from "@/components/purchase/order-form-provider/builder/OrderFormProviderHeader.vue";
import OrderFormProviderTable from "@/components/purchase/order-form-provider/builder/OrderFormProviderTable.vue";
import OrderFormProviderBTools from "@/components/purchase/order-form-provider/builder/OrderFormProviderBTools.vue";
import OrderFormProviderFooter from "@/components/purchase/order-form-provider/builder/OrderFormProviderFooter.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    OrderFormProviderHeader,
    OrderFormProviderTable,
    OrderFormProviderBTools,
    OrderFormProviderFooter,
  },
  computed: {
    ...mapGetters(["institution", "institutionSettingsActive"]),
    document: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
    documentDetails: {
      get() {
        return this.$store.getters["getOrderFormProviderDetails"];
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_DETAILS", value);
      },
    },
    mandatoryInformationMissingNumber() {
      if (
        this.document.institutionLegalForm == 0 ||
        this.document.institutionLegalForm == 1
      ) {
        var mandatoryInformation = [
          "institutionTvaNumber",
          "institutionInsuranceName",
          "institutionGuaranteeType",
          "institutionInsuranceCoverage",
          "institutionInsuranceAddress",
        ];
      } else if (
        this.document.institutionLegalForm == 2 ||
        this.document.institutionLegalForm == 3
      ) {
        var mandatoryInformation = [
          "institutionSiret",
          "institutionApeCode",
          "institutionTvaNumber",
          "institutionInsuranceName",
          "institutionGuaranteeType",
          "institutionInsuranceCoverage",
          "institutionInsuranceAddress",
        ];
      } else {
        var mandatoryInformation = [
          "institutionSiret",
          "institutionRcsCity",
          "institutionCapital",
          "institutionApeCode",
          "institutionTvaNumber",
          "institutionInsuranceName",
          "institutionGuaranteeType",
          "institutionInsuranceCoverage",
          "institutionInsuranceAddress",
        ];
      }
      let missing = 0;
      for (let i = 0; i < mandatoryInformation.length; i++) {
        const mandatory = mandatoryInformation[i];
        if (
          this.document[mandatory] === null ||
          this.document[mandatory] === ""
        ) {
          missing++;
        }
      }
      return missing;
    },
    institutionInformationsNotUpToDate() {
      return (
        this.institution.name != this.document.institutionName ||
        this.institution.legalForm != this.document.institutionLegalForm ||
        this.institution.address != this.document.institutionAddress ||
        this.institution.addressComplement !=
          this.document.institutionAddressComplement ||
        this.institution.zipCode != this.document.institutionZipCode ||
        this.institution.city != this.document.institutionCity ||
        this.institution.country != this.document.institutionCountry ||
        this.institution.phoneNumber != this.document.institutionPhoneNumber ||
        this.institution.secondaryPhoneNumber !=
          this.document.institutionSecondaryPhoneNumber ||
        this.institution.email != this.document.institutionEmail ||
        this.institution.webSite != this.document.institutionWebSite ||
        this.institution.registrationNumber !=
          this.document.institutionRegistrationNumber ||
        this.institution.isSubjectTVA !=
          this.document.institutionIsSubjectTVA ||
        this.institution.tvaNumber != this.document.institutionTvaNumber ||
        this.institution.siret != this.document.institutionSiret ||
        this.institution.rcsCity != this.document.institutionRcsCity ||
        this.institution.capital != this.document.institutionCapital ||
        this.institution.apeCode != this.document.institutionApeCode ||
        this.institution.guaranteeType !=
          this.document.institutionGuaranteeType ||
        this.institution.insuranceName !=
          this.document.institutionInsuranceName ||
        this.institution.insuranceCoverage !=
          this.document.institutionInsuranceCoverage ||
        this.institution.insuranceAddress !=
          this.document.institutionInsuranceAddress ||
        this.institution.insuranceAddressComplement !=
          this.document.institutionInsuranceAddressComplement ||
        this.institution.insuranceCity !=
          this.document.institutionInsuranceCity ||
        this.institution.insuranceZipCode !=
          this.document.institutionInsuranceZipCode ||
        this.institution.insuranceCountry !=
          this.document.institutionInsuranceCountry ||
        this.institution.color != this.document.institutionColor ||
        this.institution.secondaryColor !=
          this.document.institutionSecondaryColor ||
        this.institutionSettingsActive.footerType != this.document.footerType ||
        this.institutionSettingsActive.footer != this.document.footer
      );
    },
  },
  methods: {
    ...mapActions(["updateOrderForm"]),
    redirectToInstitution() {
      this.$router.push({
        name: "edit-institution",
        params: {
          id: this.document.institutionId,
          title: "Edit : " + this.document.institutionName,
          tips: "Editer la société : " + this.document.institutionName,
          routeOrigine: "institutions",
        },
      });
    },
    saveAction(bool) {
      this.updateOrderForm({
        orderFormProvider: this.document,
        loading: bool,
      });
    },
    updateDocumentInstitutionInformations() {
      this.document.institutionName = this.institution.name;
      this.document.institutionLegalForm = this.institution.legalForm;
      this.document.institutionAddress = this.institution.address;
      this.document.institutionAddressComplement =
        this.institution.addressComplement;
      this.document.institutionZipCode = this.institution.zipCode;
      this.document.institutionCity = this.institution.city;
      this.document.institutionCountry = this.institution.country;
      this.document.institutionPhoneNumber = this.institution.phoneNumber;
      this.document.institutionSecondaryPhoneNumber =
        this.institution.secondaryPhoneNumber;
      this.document.institutionEmail = this.institution.email;
      this.document.institutionWebSite = this.institution.webSite;
      this.document.institutionRegistrationNumber =
        this.institution.registrationNumber;
      this.document.institutionIsSubjectTVA = this.institution.isSubjectTVA;
      this.document.institutionTvaNumber = this.institution.tvaNumber;
      this.document.institutionSiret = this.institution.siret;
      this.document.institutionRcsCity = this.institution.rcsCity;
      this.document.institutionCapital = this.institution.capital;
      this.document.institutionApeCode = this.institution.apeCode;
      this.document.institutionGuaranteeType = this.institution.guaranteeType;
      this.document.institutionInsuranceName = this.institution.insuranceName;
      this.document.institutionInsuranceCoverage =
        this.institution.insuranceCoverage;
      this.document.institutionInsuranceAddress =
        this.institution.insuranceAddress;
      this.document.institutionInsuranceAddressComplement =
        this.institution.insuranceAddressComplement;
      this.document.institutionInsuranceCity = this.institution.insuranceCity;
      this.document.institutionInsuranceZipCode =
        this.institution.insuranceZipCode;
      this.document.institutionInsuranceCountry =
        this.institution.insuranceCountry;
      this.document.institutionColor = this.institution.color;
      this.document.institutionSecondaryColor = this.institution.secondaryColor;
      this.document.footerType = this.institutionSettingsActive.footerType;
      this.document.footer = this.institutionSettingsActive.footer;
      this.saveAction(false);
    },
  },
};
</script>

<style scoped>
#invoiceContainer {
  height: calc(100% - 23px) !important;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.builder-area {
  /* margin-top: 20px; */
  padding: 20px;
  background-color: white !important;
  border: solid 2px #f1f1f1;
}
</style>

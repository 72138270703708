<template lang="pug">
div(v-if="preview")
  div.content-span-document-detail
    span.mr-2 {{ selected(line[dataKey]) }}
div(v-else)
  select.form-select.custom-select(@focus="emitEditFocus(true)" v-model="line[dataKey]" :class="edit && canEdit ? 'edit' : 'no-edit'" aria-label='Default select example' :disabled="(dataKey == 'referencielTvaId' && (orderFormOption.isReverseCharge || !canEdit || line.isDisabled))" @change="change(dataKey)")
    option(v-for='(item, index) in orderFormProviderHeader[dataKey].choice' :value="item.id")  {{ item.label }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { referencielTvaTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  props: {
    preview: {
      default: false,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    orderFormProviderHeader: {
      type: Object,
      required: false,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
    mouseOn: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(["orderFormOption"]),
    document: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "updateOrderFormProviderDetail",
      "loopOrderFormProviderDetails",
    ]),
    referencielTvaTranslate,
    selected(id) {
      if (
        this.orderFormProviderHeader[this.dataKey].choice.find(
          (el) => el.id == id
        )
      ) {
        return this.orderFormProviderHeader[this.dataKey].choice.find(
          (el) => el.id == id
        ).label;
      } else {
        return "--";
      }
    },
    change(dataKey) {
      if (dataKey == "referencielTvaId") {
        this.changeAllReferencielTva();
      } else {
        this.blurFunction(false);
      }
    },
    blurFunction(res) {
      this.emitEditFocus(res);
      this.updateOrderFormProviderDetail({
        orderFormProviderDetail: this.line,
      });
    },
    emitEditFocus(res) {
      this.$emit("emitEditFocus", res);
    },
    changeAllReferencielTva() {
      let documentDetailIds = [];
      // Autoliquidation
      if (this.dataKey == "referencielTvaId" && this.line[this.dataKey] == 5) {
        this.$bvModal
          .msgBoxConfirm("Est-ce une autoliquidation ?", {
            title: "TVA à 0% détectée",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui, appliquer à toutes les lignes",
            cancelTitle: "Non",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.orderFormOption.isReverseCharge = true;
              this.loopOrderFormProviderDetails(5);
            } else {
              this.updateOrderFormProviderDetail({
                orderFormProviderDetail: this.line,
              });
            }
          });
      } else if (
        this.dataKey == "referencielTvaId" &&
        this.line[this.dataKey] != 5
      ) {
        this.$bvModal
          .msgBoxConfirm(
            "Voulez-vous appliquer cette TVA à toutes les autres lignes ?",
            {
              title:
                "TVA à " +
                referencielTvaTranslate(this.line[this.dataKey]) +
                " détectée",
              size: "sm",
              okVariant: "primary",
              okTitle: "Oui, seulement aux existantes",
              cancelTitle: "Non",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.loopOrderFormProviderDetails(this.line[this.dataKey]);
            } else {
              this.updateOrderFormProviderDetail({
                orderFormProviderDetail: this.line,
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<template lang="pug">
    div
      .document-head.bg-primary
          .product-line.builder
              div(v-for="(key, i) in Object.keys(orderFormProviderHeader)" :key="i" v-if="orderFormProviderHeader[key].display" :class="orderFormProviderHeader[key].display ? orderFormProviderHeader[key].class : ''" :style="orderFormProviderHeader[key].style") {{orderFormProviderHeader[key].text}}  
  
      
  </template>
<script>
export default {
  data() {
    return {};
  },

  //options:
  computed: {
    orderFormProviderHeader: {
      get() {
        return this.$store.getters.getOrderFormProviderHeader;
      },
      set(val) {
        this.$store.commit("setOrderFormProviderHeader", val);
      },
    },
  },
};
</script>
<!-- <style scoped>
  #invoiceBuilder #builderPreview .document-table .document-head .product-line {
    padding: 0.2rem 2.5rem 0.2rem 1rem;
    font-size: 14px !important;
    /* background: #38a1f3; */
    color: #fff;
    display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      
  }
  </style> -->

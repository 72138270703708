<template lang="pug">
    div.d-flex.justify-content-end.no-printting
      span.cursor-pointer.text-primary(v-if="!orderFormOption.showDiscount" @click='activeDiscountGlobal') + Ajouter une remise
      span.cursor-pointer.text-primary(v-else @click='desactiveDiscountGlobal') - Supprimer la remise
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    preview: {
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    document: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
    orderFormOption: {
      get() {
        return this.$store.getters.orderFormOption;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_OPTION", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateOrderFormOption", "updateOrderForm"]),
    activeDiscountGlobal() {
      this.document = {
        ...this.document,
        discountGlobalType: 0,
        discountGlobalNumber: 0,
      };
      this.orderFormOption.showDiscount = true;
      this.saveAction();
    },
    desactiveDiscountGlobal() {
      this.document = {
        ...this.document,
        discountGlobalType: 0,
        discountGlobalNumber: 0,
      };
      this.orderFormOption.showDiscount = false;
      this.saveAction();
    },
    saveAction() {
      this.updateOrderFormOption({
        orderFormOption: this.orderFormOption,
      });
      this.updateOrderForm({
        orderFormProvider: this.document,
      });
    },
  },
};
</script>

<template lang="pug">
    div(style='display:contents')
      div(v-if="isLoadingOrderFormProvider || isLoadingOrderFormProviderPDF || isValidatingOrderFormProvider" class="h-100")
        .text-center.flex-center.h-100
          .loading-bg-inner
            .loader
              .outer
              .middle
              .inner
          .mt-5
            br
            br
            br
            |  {{ isValidatingOrderFormProvider ? "Enregistrement de l'ordre de commande en cours, veuillez patienter..." : "Génération du PDF..." }}
      div(style='display:contents' v-show="!isLoadingOrderFormProvider && !isLoadingOrderFormProviderPDF && !isValidatingOrderFormProvider")
        iframe.pdfViewver(:src="orderFormProviderPDF" style="width: 100%;height: 100%; border: solid 2px #f1f1f1; padding:0px") 
        div(style="display:contents" v-if="orderFormProvider.status < 5 || !orderFormProvider.path || orderFormProvider.path.includes('null.pdf')")
          OrderFormProviderPdfContent.pdfViewver(style="width: 100%;height: 100%; border: none; padding: 0px; display:none")
            table.structure
              thead
                tr
                  td
                    .page-header-space
              tbody
                tr
                  td
                    div#invoiceContainer.pdfViewver
                      OrderFormProviderPreviewHeader()
                      OrderFormProviderPreviewTable()
                      OrderFormProviderPreviewFooter()
                      .watermark(v-if="orderFormProvider.status == 0 || orderFormProvider.status == 1")
                        .text PROVISOIRE
                      .watermark(v-else-if="orderFormProvider.status == 2")
                        .text A VALIDER
                      .watermark(v-else-if="orderFormProvider.status == 5")
                        .text ANNULÉ
              tfoot
                tr
                  td
                    .page-footer-space(:style="'height:'+pageFooterHeight()+'px !important'")
                      #pageFooter
</template>

<script>
import OrderFormProviderPdfContent from "@/components/purchase/order-form-provider/OrderFormProviderPdfContent";
import OrderFormProviderPreviewHeader from "@/components/purchase/order-form-provider/preview/OrderFormProviderPreviewHeader.vue";
import OrderFormProviderPreviewTable from "@/components/purchase/order-form-provider/preview/OrderFormProviderPreviewTable.vue";
import OrderFormProviderPreviewFooter from "@/components/purchase/order-form-provider/preview/OrderFormProviderPreviewFooter.vue";
import { mapGetters } from "vuex";
import { statusToUpperCaseTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  computed: {
    ...mapGetters([
      "orderFormProvider",
      "orderFormProviderPDF",
      "isLoadingOrderFormProvider",
      "isLoadingOrderFormProviderPDF",
      "isUpdatingOrderFormProvider",
      "isValidatingOrderFormProvider",
      "institutionSettingsActive",
    ]),
    heightFooter() {
      return "100";
    },
  },
  components: {
    OrderFormProviderPdfContent,
    OrderFormProviderPreviewHeader,
    OrderFormProviderPreviewTable,
    OrderFormProviderPreviewFooter,
  },
  methods: {
    statusToUpperCaseTranslate,
    htmlEntities(str) {
      return String(str)
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;");
    },
    pageFooterHeight() {
      var count = 0;
      if (this.orderFormProvider.footer) {
        count =
          ((this.orderFormProvider.footer.match(/\<p/g) || []).length +
            (this.orderFormProvider.footer.match(/\<br/g) || []).length +
            (this.orderFormProvider.footer.match(/\<\/br/g) || []).length) *
          12;
      }
      if (count > 50) {
        return count;
      } else {
        return 50;
      }
    },
    decodeHtmlEntities(str) {
      var element = document.createElement("div");
      if (str && typeof str === "string") {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = "";
      }
      return str;
    },
  },
};
</script>

<template lang="pug">
div(v-if="!preview" style='max-width:400px')
  div.d-flex(:class="edit ? 'justify-content-between' : ''" style="margin-bottom:5px")
    .d-flex.flex-column
      span.mb-1(@mouseover="showTools = true" @mouseleave="showTools = false") {{ secondText + ' : '}}
        span {{ paymentConditionLabel }}
      span.mb-1(@mouseover="showTools = true" @mouseleave="showTools = false") {{ principalText + ' : '}}
        span {{ begginingWorkDate }}
          span.cursor-pointer.bold(v-if="showTools  && !disabled")
            feather-icon(icon="Edit3Icon" size="18"  @click="edit=true" v-b-tooltip.hover.top="`Modifier la date d'échéance`")
            a(v-if="document[keyValue] == null " @click="edit = !edit") définir
    span.cursor-pointer.text-primary(v-if="edit"  @click="edit = false") Fermer
  div.mb-1.d-flex(v-if="edit")
    div.w-100
      v-select#paymentCondition(@input="changeDate" :clearable="false" :loading='isLoadingPaymentConditionsList' :deselectFromDropdown='true' :closeOnSelect='true' v-model='document.paymentConditionId' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="\
        paymentConditionsList\
        " :reduce='elem => elem.id')
          template(v-slot:no-options='')
            template  Aucun r&eacute;sultat trouv&eacute;
  div.mb-1.d-flex(v-if="edit")
    div.w-100
      date-range-picker(v-if="document.paymentConditionId != 9" :showDropdowns='true' ref="picker" id="dates" style="width: 100%" :show-week-numbers="true" :timePicker="false" :single-date-picker="true" :opens="'right'" :append-to-body="true" :locale-data="config" :ranges="defaultRange" v-model="datePicker" :auto-apply="true")
div(v-else)
  div.mb-1
    span {{ secondText + ' : '}}
      span {{ paymentConditionLabel }}
  div.mb-1
    span {{  principalText + ' : ' }}
      span {{  begginingWorkDate }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
const config = {
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  firstDay: 1,
};

var defaultRange = {
  "Aujourd'hui": [new Date(dayjs()), new Date(dayjs())],
  Demain: [new Date(dayjs().add(1, "day")), new Date(dayjs().add(1, "day"))],
  Hier: [
    new Date(dayjs().subtract(1, "day")),
    new Date(dayjs().subtract(1, "day")),
  ],
  "Dans 1 mois": [
    new Date(dayjs().add(1, "month")),
    new Date(dayjs().add(1, "month")),
  ],
};
export default {
  components: {
    DateRangePicker,
    "v-select": vSelect,
  },
  props: {
    preview: {
      default: false,
    },
    text: {
      type: String,
      default: "Date d'échéance :",
    },
    principalText: {
      type: String,
      default: "Date d'échéance",
    },
    secondText: {
      type: String,
      default: "Règlement",
    },
    keyValue: {
      type: String,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      config: config,
      defaultRange: defaultRange,
      edit: false,
      showTools: false,
    };
  },
  created() {
    if (!this.paymentConditionsList || this.paymentConditionsList.length == 0) {
      this.getPaymentConditionByWorkspaceId({});
    }
  },
  methods: {
    ...mapActions(["getPaymentConditionByWorkspaceId"]),
    changeDate(value) {
      let date = new Date(this.document.documentDate);
      if (value == 1) {
        this.document.limitDate = date.toISOString();
      } else {
        let find = this.paymentConditionsList.find((el) => el.id == value);
        if (find) {
          this.document.limitDate = dayjs(date)
            .add(find.days, "day")
            .toISOString();
        } else {
          this.document.limitDate = date.toISOString();
        }
      }
      this.edit = false;
      this.$emit("setValue");
    },
    personalizedDate(value) {
      this.document.paymentConditionId = 1;
      this.$emit("setValue");
    },
  },
  computed: {
    ...mapGetters(["paymentConditionsList", "isLoadingPaymentConditionsList"]),
    begginingWorkDate() {
      return dayjs(this.document.limitDate).format("DD/MM/YYYY");
    },
    datePicker: {
      get() {
        return {
          startDate: dayjs(this.document.limitDate),
          endDate: dayjs(this.document.limitDate),
        };
      },
      set(value) {
        this.document.limitDate = new Date(value.startDate);
        this.document.paymentConditionId = 1;
        this.$emit("setValue");
        this.edit = false;
      },
    },
    document: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
    paymentConditionLabel() {
      const paymentCondition = this.paymentConditionsList?.find(
        (paymentCondition) =>
          paymentCondition.id === this.document.paymentConditionId
      );

      return paymentCondition?.label || "";
    },
  },
};
</script>
